import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformCheckGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('Inside Platform check guard', window.location.href);

    if (this.isPlayLoHomePage() && window.location.href.indexOf('/landing') === -1) {
      this.router.navigate(['/landing'], {replaceUrl: true, queryParamsHandling: 'merge'}).then();
      return false;
    } else if (this.isPlayLoCreatorPage() && window.location.href.indexOf('playlo.in/creator') === -1) {
      this.router.navigate(['/creator'], {replaceUrl: true, queryParamsHandling: 'merge'}).then();
      return false;
    } else if (this.isGiftCardBase() && window.location.href.indexOf('/gift-cards') === -1) {
      this.router.navigate(['/gift-cards'], {replaceUrl: true, queryParamsHandling: 'merge'}).then();
      return false;
    } else {
      return true;
    }
  }

  isPlayLoHomePage = () => window.location.href.indexOf('home.playlo.in') !== -1;

  isPlayLoCreatorPage = () => window.location.href.indexOf('creator.playlo.in') !== -1;

  isGiftCardBase = () => window.location.href.indexOf('highfox.shop') !== -1;
}
