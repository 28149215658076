import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-players-group-widget',
  templateUrl: './players-group-widget.component.html',
  styleUrls: ['./players-group-widget.component.scss'],
})
export class PlayersGroupWidgetComponent implements OnInit {
  @Input() team;
  @Input() playersCount;

  constructor(
    public global: GlobalService
  ) {
  }

  ngOnInit() {
    if (!this.playersCount) {
      this.playersCount = this.team?.lineup?.players?.length;
    }
  }

  getFilteredTeam = () => this.team?.lineup?.players?.sort(playerId => (this.team?.lineup?.captain === playerId ? -2 : this.team?.lineup?.viceCaptain === playerId ? -1 : 0));
}
