import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PlatformCheckGuard} from './guards/platform-check.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'contests',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full',
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'contests/',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full',
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'contests',
    loadChildren: () => import('./contest-detail/contest-detail.module').then(m => m.ContestDetailPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'create-contest',
    loadChildren: () => import('./create-contest/create-contest.module').then(m => m.CreateContestPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'created-contests',
    loadChildren: () => import('./created-contests/created-contests.module').then(m => m.CreatedContestsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'upload-media',
    loadChildren: () => import('./upload-media/upload-media.module').then(m => m.UploadMediaPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'upload-apk',
    loadChildren: () => import('./upload-apk/upload-apk.module').then(m => m.UploadApkPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'match-players',
    loadChildren: () => import('./match-players/match-players.module').then(m => m.MatchPlayersPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'rewards',
    loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./withdraw/withdraw.module').then(m => m.WithdrawPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServicePageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'responsible-play',
    loadChildren: () => import('./responsible-play/responsible-play.module').then(m => m.ResponsiblePlayPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'following',
    loadChildren: () => import('./following/following.module').then(m => m.FollowingPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'followers',
    loadChildren: () => import('./followers/followers.module').then(m => m.FollowersPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'taxation',
    loadChildren: () => import('./taxation/taxation.module').then(m => m.TaxationPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'link',
    loadChildren: () => import('./link/link.module').then(m => m.LinkPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'txn-details',
    loadChildren: () => import('./txn-details/txn-details.module').then(m => m.TxnDetailsPageModule),
    canActivate: [PlatformCheckGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'creator',
    loadChildren: () => import('./creator/creator.module').then(m => m.CreatorPageModule)
  },
  {
    path: 'gift-cards',
    loadChildren: () => import('./gift-cards/gift-cards.module').then(m => m.GiftcardsPageModule)
  },
  {
    path: '**',
    redirectTo: '/contests/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
