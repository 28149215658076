import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-match-widget',
  templateUrl: './match-widget.component.html',
  styleUrls: ['./match-widget.component.scss'],
})
export class MatchWidgetComponent implements OnInit {
  @Input() isActive = false;
  @Input() match;
  @Input() contests;
  @Input() coverFullWidth = false;

  constructor(
    public global: GlobalService
  ) {
  }

  ngOnInit() {
  }

  getTimeLeft = () => moment(this.match?.start_at).fromNow();

  getMatchFormatLabel = (format) => {
    if (format === 't10') {
      return 'T10';
    } else if (format === 't20') {
      return 'T20';
    } else if (format === 'oneday') {
      return 'ODI';
    } else if (format === 'test') {
      return 'TEST';
    }
    return format?.toUpperCase();
  };

  isWinning = () => this.contests?.find(contest => this.global.isWinning(contest));

  getMyWinnings = () => this.global.formatNumber(this.contests.reduce((acc, contest) => (acc + +this.global.getMyWinnings(contest)), 0), 0);

  getWinningContestsCount = () => this.contests?.filter(contest => this.global.isWinning(contest))?.length || 0;
}
