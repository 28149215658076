import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnInit, OnChanges {
  @Input() type = 'player';
  @Input() playerId = '';
  @Input() imgUrl = '';
  @Input() name = '';
  @Input() bgColor = '';
  @Input() hideFlag = false;
  @Input() match: any = {};
  @Input() useBase64 = false;
  isLoaded = false;
  isError = false;
  base64Dp: any = '';

  constructor(
    public global: GlobalService,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    if (!this.getImgUrl()) {
      this.isError = true;
    }
  }

  ngOnChanges() {
    if (this.imgUrl) {
      this.isError = false;
    }

    if (!this.base64Dp && this.playerId && this.getImgUrl() && this.useBase64 && !this.global.isIOSDevice()) {
      this.api.getBase64Path({imgUrl: this.getImgUrl()}).subscribe(res => {
        if (res?.result) {
          this.base64Dp = res?.result;
        }
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
    }
  }

  getImgUrl = () => this.imgUrl || (this.type === 'player' && this.global.getPlayerImg(this.playerId)) || '';
  getInitials = () => this.global.getInitials(this.name || this.global.getPlayerName(this.playerId));

  onErrorImg = () => {
    console.log('ERR IMG');
    this.isError = true;
  };

  onLoadImg = () => {
    this.isLoaded = true;
  };

  getTeamCode = (playerId) => this.match?.squad?.a?.player_keys?.includes(playerId) ? this.match?.teams?.a?.code : this.match?.teams?.b?.code;
}
