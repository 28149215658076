import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderComponent} from '../components/header/header.component';
import {FooterComponent} from '../components/footer/footer.component';
import {HamburgerMenuComponent} from '../components/hamburger-menu/hamburger-menu.component';
import {MatchWidgetComponent} from '../components/contests/match-widget/match-widget.component';
import {ContestWidgetComponent} from '../components/contests/contest-widget/contest-widget.component';
import {LoginModalComponent} from '../components/login-modal/login-modal.component';
import {LoaderComponent} from '../components/loader/loader.component';
import {MatchStatsComponent} from '../components/contests/match-stats/match-stats.component';
import {HttpClient} from '@angular/common/http';
import {PlayersGroupWidgetComponent} from '../components/contests/players-group-widget/players-group-widget.component';
import {ProfilePhotoComponent} from '../components/profile-photo/profile-photo.component';
import {TeamWidgetComponent} from '../components/contests/team-widget/team-widget.component';
import {InfoModalComponent} from '../components/info-modal/info-modal.component';
import {HowToPlayComponent} from '../components/how-to-play/how-to-play.component';
import {AddMoneyComponent} from '../components/add-money/add-money.component';
import {IonicStorageModule} from '@ionic/storage-angular';
import {MatchScoreboardComponent} from '../components/match-scoreboard/match-scoreboard.component';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HamburgerMenuComponent,
    MatchWidgetComponent,
    ContestWidgetComponent,
    PlayersGroupWidgetComponent,
    LoginModalComponent,
    LoaderComponent,
    MatchStatsComponent,
    ProfilePhotoComponent,
    TeamWidgetComponent,
    InfoModalComponent,
    HowToPlayComponent,
    AddMoneyComponent,
    MatchScoreboardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    NgOptimizedImage,
    IonicStorageModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    TranslateModule,
    IonicStorageModule,
    NgOptimizedImage,
    HeaderComponent,
    FooterComponent,
    HamburgerMenuComponent,
    MatchWidgetComponent,
    ContestWidgetComponent,
    LoginModalComponent,
    LoaderComponent,
    MatchStatsComponent,
    PlayersGroupWidgetComponent,
    ProfilePhotoComponent,
    TeamWidgetComponent,
    InfoModalComponent,
    HowToPlayComponent,
    AddMoneyComponent,
    MatchScoreboardComponent
  ],
  providers: [
    TranslateService,
    TranslateStore
  ]
})
export class SharedModule {
}
