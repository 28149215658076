import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-match-scoreboard',
  templateUrl: './match-scoreboard.component.html',
  styleUrls: ['./match-scoreboard.component.scss'],
})
export class MatchScoreboardComponent implements OnInit {
  @Input() match;
  @Input() contests: any = [];
  @Input() fantasyScores: any = {};
  playingTeam = 'a';
  type = 'scores';
  allMetrics = [
    {label: 'Runs', value: 0},
    {label: '4s', value: 1},
    {label: '6s', value: 2},
    {label: 'Half-Century', value: 3},
    {label: 'Century', value: 4},
    {label: 'Duck', value: 5},
    {label: 'Wickets', value: 6},
    {label: '4 Wickets', value: 7},
    {label: '5 Wickets', value: 8},
    {label: 'Maiden', value: 9},
    {label: 'Catches', value: 10},
    {label: 'Stumping', value: 11},
    {label: 'Runouts', value: 12},
    {label: 'Eco rate', value: 13},
    {label: 'Strike rate', value: 14}
  ];

  constructor(
    public global: GlobalService,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    this.playingTeam = this.firstBattingTeam();
  }

  getPlayersList = (playingTeam = this.playingTeam) => {
    const playingSquad = this.match?.squad?.[playingTeam]?.playing_xi || [];
    this.match?.play?.innings?.[playingTeam + '_1']?.batting_order?.map(playerId => {
      if (!playingSquad?.includes(playerId)) {
        playingSquad.push(playerId);
      }
    });
    this.match?.play?.innings?.[playingTeam + '_1']?.bowling_order?.map(playerId => {
      if (!playingSquad?.includes(playerId)) {
        playingSquad.push(playerId);
      }
    });
    return playingSquad;
  };

  getYetToComePlayersList = (playingTeam = this.playingTeam) => {
    const playing11 = this.getPlayersList(playingTeam);
    return playing11?.filter(playerId => !this.getPlayerStats(playerId)?.balls)?.map(playerId => this.global.players?.[playerId]?.jersey_name || playerId)?.join(', ');
  };

  getPlayerStats = (playerId, role = 'batting') => {
    const playerObj = this.match?.players?.[playerId];
    const inn1Score = {...playerObj?.score?.['1']?.[role]?.score};
    return inn1Score;
  };

  firstBattingTeam = () => this.match?.play?.first_batting;

  secondBattingTeam = () => this.match?.play?.first_batting === 'b' ? 'a' : 'b';

  isSecondTeamStartedBatting = () => this.match?.play?.innings?.[this.secondBattingTeam() + '_1']?.overs?.[0] || this.match?.play?.innings?.[this.secondBattingTeam() + '_1']?.overs?.[1];

  isAnyBowlerBowled = () => this.getPlayersList(this.playingTeam === 'a' ? 'b' : 'a')?.find(playerId => this.getPlayerStats(playerId, 'bowling')?.overs?.[0] || this.getPlayerStats(playerId, 'bowling')?.overs?.[1] || playerId === this.match?.play?.live?.bowler_key);

  getMetricPoints = (playerId, metricIndex) => this.fantasyScores?.[playerId]?.points_breakup?.find(item => item?.metric_rule_index === metricIndex)?.points || 0;
}
