import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../../services/global.service';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {UserService} from '../../../services/user.service';
import {AnalyticsService} from '../../../services/analytics.service';

declare const window: any;

@Component({
  selector: 'app-contest-widget',
  templateUrl: './contest-widget.component.html',
  styleUrls: ['./contest-widget.component.scss'],
})
export class ContestWidgetComponent implements OnInit {
  @Input() contest: any;
  @Input() match;
  @Input() isJoined;
  @Input() compact = false;
  @Input() page;
  @Input() hideJoinCta = false;

  contestTypes = [
    {value: 'playing5', label: 'Playing 5', maxPlayers: 5},
    {value: 'playing3', label: 'Playing 3', maxPlayers: 3},
    {value: 'batting5', label: 'Batting 5', maxPlayers: 5},
    {value: 'batting3', label: 'Batting 3', maxPlayers: 3},
    {value: 'bowling5', label: 'Bowling 3', maxPlayers: 3}
  ];

  orderId = '';

  constructor(
    public global: GlobalService,
    private api: ApiService,
    public user: UserService,
    private router: Router,
    public analytics: AnalyticsService
  ) {
  }

  ngOnInit() {
  }

  onContestClick = () => {
    if (this.page === 'detailPage') {
    } else {
      this.analytics.trackEvent('contestWidget_click', {
        contestId: this.contest?._id,
        creatorId: this.contest?.creator?.id,
        creatorName: this.contest?.creator?.profile?.displayName,
        entryFee: this.contest?.joinOptions?.costs?.[0]?.currencyValue || 0,
        isJoined: this.global.isContestJoined(this.contest)
      });

      if (this.page === 'creation' && !['active', 'active_build', 'active_review', 'full'].includes(this.contest?.state)) {
        this.router.navigate(['/create-contest'], {
          queryParams: {
            matchId: this.match?._id,
            contestId: this.contest?._id
          }
        }).then();
      } else {
        this.router.navigate(['/contests/' + this.contest?._id], {queryParams: {matchId: this.match?._id}}).then();
      }
    }
  };

  joinContest = () => {
    this.analytics.trackEvent('contestWidget_joinNow_click', {
      contestId: this.contest?._id,
      creatorId: this.contest?.creator?.id,
      creatorName: this.contest?.creator?.profile?.displayName,
      entryFee: this.contest?.joinOptions?.costs?.[0]?.currencyValue || 0,
      isJoined: this.global.isContestJoined(this.contest)
    });
  };

  isMatchStarted = () => !this.match?.start_at || moment(this.match?.start_at).diff(moment(), 'seconds') < 0;

  getTimeLeft = () => moment(this.contest?.operateOptions?.startTime).fromNow();

  areAllPlayersPlaying = () => !this.global.teams?.[this.global.getMyTeamId(this.contest)]?.lineup?.players?.find(playerId => !this.isPlaying(playerId));

  isPlaying = (playerId) => (!this.match?.squad?.a?.playing_xi?.length || !this.match?.squad?.b?.playing_xi?.length) ||
    this.match?.squad?.a?.playing_xi
      ?.concat(this.match?.squad?.b?.playing_xi)
      ?.concat(this.match?.play?.innings?.a_1?.batting_order)
      ?.concat(this.match?.play?.innings?.a_1?.bowling_order)
      ?.concat(this.match?.play?.innings?.b_1?.batting_order)
      ?.concat(this.match?.play?.innings?.b_1?.bowling_order)
      ?.includes(playerId);
}

