import {Component, OnInit} from '@angular/core';
import firebase from 'src/lib/firebase';
import {Router} from '@angular/router';
import {GlobalService} from '../../services/global.service';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../../services/user.service';
import {StorageService} from '../../services/storage.service';
import {AnalyticsService} from '../../services/analytics.service';

declare const window: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  view = '';
  phoneNum = '';
  otp = '';
  isOtpSent = false;
  name = '';
  isConsentChecked = true;

  constructor(
    private router: Router,
    public global: GlobalService,
    private modalCtrl: ModalController,
    private api: ApiService,
    public user: UserService,
    private storage: StorageService,
    public analytics: AnalyticsService
  ) {
    this.view = this.user.isLoggedIn() ? 'updateProfile' : '';
  }

  ngOnInit() {
    if (this.user.isLoggedIn()) {
      this.name = this.user.userObj?.profile?.displayName || '';
    }
  }

  ionViewWillEnter() {
    this.analytics.trackEvent('loginModal_init');
  }

  onPhoneNumberChange = (event) => {
    this.phoneNum = (event.target as HTMLInputElement).value;
    this.otp = '';
    this.isOtpSent = false;
  };

  onOtpChange = (event) => {
    this.otp = (event.target as HTMLInputElement).value;
  };

  onNameChange = (event) => {
    this.name = (event.target as HTMLInputElement).value;
  };

  sendOtp = async () => {
    this.analytics.trackEvent('loginModal_sendOtp_click');

    if (!this.isConsentChecked) {
      this.global.showToast('Please agree to the terms and conditions to continue!').then();
      return;
    } else if (this.phoneNum?.toString()?.length !== 10) {
      this.global.showToast('Please enter a valid 10 digit phone number!').then();
      return;
    }

    this.global.showLoader = true;
    this.api.sendOtp({
      phoneNum: this.phoneNum
    }).subscribe(res => {
      this.global.showLoader = false;
      this.isOtpSent = true;
      // this.requestOtpFromWeb().then();
    }, (err: HttpErrorResponse) => {
      this.global.showLoader = false;
      this.global.showToast(err?.error?.message || err?.message || JSON.stringify(err)).then();
      this.modalCtrl.dismiss(null, 'error').then();
    });
  };

  // requestOtpFromWeb = async () => {
  //   if ('OTPCredential' in window) {
  //     const abortController = new AbortController();
  //     setTimeout(() => {
  //       abortController.abort();
  //     }, 10 * 1000);
  //
  //     const o: any = {
  //       otp: {transport: ['sms']},
  //       signal: abortController.signal
  //     };
  //
  //     const content: any = await window.navigator?.credentials?.get(o);
  //     if (content?.code) {
  //       this.otp = content?.code;
  //       this.verifyOtp();
  //     }
  //   }
  // };

  verifyOtp = () => {
    this.analytics.trackEvent('loginModal_verifyOtp_click');

    if (this.otp?.toString()?.length !== 6) {
      this.global.showToast('Please enter a valid OTP!').then();
      return;
    }

    this.global.showLoader = true;
    setTimeout(() => {
      this.global.showLoader = false;
    }, 3000);
    this.api.verifyOtp({
      phoneNum: this.phoneNum,
      otp: this.otp,
      extraData: {...this.analytics.utmParams}
    }).subscribe(async (res) => {
      const resBody = res?.body?.result || res?.data?.result;
      if (resBody?._id) {
        this.user.profileObserver.next(resBody);

        const xToken = res?.headers?.['x-token'] || res?.headers?.['X-TOKEN'] || res?.headers?.get('x-token') || res?.headers?.get('X-TOKEN');

        await firebase.auth().signInWithCustomToken(xToken).then(async () => {
          const authState = await firebase?.auth()?.currentUser;
          if (authState) {
            const {token, refreshToken} = authState;

            await this.storage.setItem('access_token', token);
            await this.storage.setItem('refresh_token', refreshToken);
            await this.storage.setItem('accessTokenSavedAt', Date.now());

            this.user.accessToken = token;
            this.user.refreshToken = refreshToken;
            this.user.accessTokenSavedAt = Date.now();
          }

          this.global.showLoader = false;
          this.user.loginObserver.next(true);

          this.analytics.trackEvent('loginModal_loggedin');
          this.analytics.setUserPropertiesForAnalytics();

          if (resBody?.profile?.displayName) {
            this.modalCtrl.dismiss(null, 'success').then();
          } else {
            this.global.showToast('₹5/- cash is added in your wallet to play your first match!').then();
            this.view = 'updateProfile';
          }
        }).catch((error) => {
          this.global.showLoader = false;
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-custom-token') {
            this.global.showToast('The token you provided is not valid.').then();
          } else {
            this.global.showToast(error?.message).then();
          }
        });
      } else {
        this.global.showLoader = false;
        this.global.showToast(res?.message || res?.err?.message || res?.body?.message || res?.body?.err?.message || res?.data?.message || res?.data?.err?.message || 'Error in login!').then();
        this.modalCtrl.dismiss(null, 'error').then();
      }
    }, (err: HttpErrorResponse) => {
      this.global.showLoader = false;
      this.global.showToast(err?.error?.message || err?.message || JSON.stringify(err)).then();
      this.modalCtrl.dismiss(null, 'error').then();
    });
  };

  onUpdateProfile = () => {
    this.analytics.trackEvent('loginModal_updateProfile_click');

    if (this.name.trim()) {
      this.global.showLoader = true;
      this.api.updateProfile({
        user: {
          ...this.user.userObj,
          profile: {
            ...this.user.userObj?.profile,
            displayName: this.name?.replace(/[^a-zA-Z ]/g, '')?.trim()
          }
        }
      }).subscribe(res => {
        this.global.showLoader = false;
        this.user.profileObserver.next(res?.result);
        this.modalCtrl.dismiss(null, 'success').then();
      }, (err: HttpErrorResponse) => {
        this.global.showLoader = false;
        this.global.showToast(err?.error?.message || err?.message || 'Please check your network connection!').then();
      });
    } else {
      this.global.showToast('Name is required').then();
    }
  };

  onTnCClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    window.open('/terms-of-service');
  };

  onPrivacyPolicyClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    window.open('/privacy-policy');
  };
}
