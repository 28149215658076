import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {from, Observable} from 'rxjs';
import {UserService} from './user.service';
import {GlobalService} from './global.service';
import {CapacitorHttp} from '@capacitor/core';
import {isPlatform} from '@ionic/angular';
import {map} from 'rxjs/operators';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  accessToken = '';

  constructor(
    private http: HttpClient,
    private user: UserService,
    private global: GlobalService,
    private storage: StorageService
  ) {
    this.getToken().then();
  }

  apiConditionalPath = () => this.user.isLoggedIn() ? 'v1' : 'public';

  bypassToken = (url) => url?.indexOf('ipwhois.app') !== -1;

  getToken = async () => {
    this.accessToken = await this.storage.getItem('access_token');
    return this.accessToken;
  };

  customGetRequest = (url, options: any = {}) => {
    this.getToken().then(val => {
      this.accessToken = val;
    });
    if (isPlatform('capacitor')) {
      const queryParams = {};
      options?.params?.keys()?.map(key => {
        queryParams[key] = options?.params?.get(key);
      });
      return from(CapacitorHttp.request({
        method: 'GET',
        url,
        params: {...queryParams},
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          ...(this.bypassToken(url) ? {} : {withCredentials: true}),
          ...options?.headers
        }
      })).pipe(map(res => options?.observe ? res : res?.data));
    } else {
      return this.http.get<any>(url, options);
    }
  };

  customPostRequest = (url, payload: any = {}, options: any = {}) => {
    this.getToken().then(val => {
      this.accessToken = val;
    });
    if (isPlatform('capacitor')) {
      return from(CapacitorHttp.post({
        method: 'POST',
        url,
        data: JSON.stringify({
          ...payload,
          platform: this.global.isInsideWebview() ? 'android-app' : 'browser'
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.accessToken}`,
          ...(this.bypassToken(url) ? {} : {withCredentials: true}),
          ...options?.headers
        }
      })).pipe(map(res => options?.observe ? res : res?.data));
    } else {
      return this.http.post<any>(url, payload, {...options});
    }
  };

  sendOtp(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/auth/getVerificationCode`, {
        code: '91',
        number: data?.phoneNum.toString()
      }
    );
  }

  verifyOtp(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/auth/checkVerificationCode`, {
        code: '91',
        number: data?.phoneNum?.toString(),
        otp: data?.otp,
        fcmToken: this.user.fcmToken,
        extraData: data?.extraData
      }, {
        observe: 'response'
      }
    );
  }

  updateFcmToken() {
    this.customPostRequest(
      `${environment.apiBase}/user/fcmToken`, {
        fcmToken: this.user.fcmToken
      }
    ).subscribe(res => {
    }, (err: HttpErrorResponse) => {
    });
  }

  getUserData(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('fcmToken', this.user.fcmToken);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/profile/data`, {params}
    );
  }

  getUserBalance(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/v1/me/balances`
    );
  }

  updateProfile(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v1/me/update`, {...data}
    );
  }

  getPaymentMethod(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/v1/me/getPaymentMethod`
    );
  }

  addPaymentMethod(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v1/me/addPaymentMethod`, {
        ...data
      }
    );
  }

  logOut(): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/auth/logout`
    );
  }

  getMatchesData(): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/match`
    );
  }

  getMatchDetails(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/match/${data?.matchId}`, {...data}
    );
  }

  getLiveMatchDetails(data: any): Observable<any> {
    let params = new HttpParams();
    data?.teamIds?.map(teamId => {
      params = params.append('teamId', teamId);
    });

    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/match/${data?.matchId}/live`, {params}
    );
  }

  getActivityData(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/activity/${data.activityId}`
    );
  }

  getProfileContestsCreated(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v2/profile/challengeCreated/?id=${data?.userId || this.user.userObj._id}`, {
        offset: data?.offset || undefined
      }
    );
  }

  getProfileParticipatedContests(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v2/profile/challengeParticipated/?id=${data?.userId || this.user.userObj._id}`, {
        offset: data?.offset || undefined
      }
    );
  }

  toggleUser(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v1/profile/${data?.action}/`, {id: data?.userId}
    );
  }

  getProfileData(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', data?.userId || this.user.userObj._id);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/profile/info/`, {params}
    );
  }

  getProfileStats(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', data?.userId || this.user.userObj._id);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/profile/stats/`, {params}
    );
  }

  getJoinedContests(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('tags', data?.activityId);
    params = params.append('activityId', data?.activityId);
    params = params.append('offset', data?.offset);
    params = params.append('count', 50);
    if (data?.force) {
      params = params.append('force', true);
    }

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/contest/joinedContest/`, {params}
    );
  }

  getContests(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.user.loginStatus ? 'v2' : 'public'}/contest/query/`, {
        opts: {activities: [data?.activityId], offset: data?.offset || 0, force: data?.force, filters: data?.filters},
        context: {},
        offset: data?.offset || 0,
        count: 10,
        fcmToken: this.user.fcmToken
      }
    );
  }

  getPublishedContests(data): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/?status=active&status=active_build&status=active_review&status=full&linkedTo=${data?.activityId}&offset=${data?.offset || ''}`
    );
  }

  getUnpublishedContests(data): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/?status=review&status=build&linkedTo=${data?.activityId}&offset=${data?.offset || ''}`
    );
  }

  publishContest(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contestId}/approve`, data?.formData || {}
    );
  }

  joinContest(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contestId}/join`, {
        context: {},
        displayName: this.user.userObj.profile.displayName,
        fcmToken: this.user.fcmToken,
        joinParams: data?.joinParams,
        multiplier: data?.multiplier || 1
      }
    );
  }

  updateMyTeam(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v2/contest/${data?.contestId}/join/update`, {
        context: {},
        displayName: this.user.userObj.profile.displayName,
        fcmToken: this.user.fcmToken,
        joinParams: data?.joinParams
      }
    );
  }

  initiateGiftCardTxn(data): Observable<any> {
    return this.customPostRequest(
      `${environment.giftCardsApiBase}/user/gift/transaction/orderTransaction`, {
        amount: data?.amount,
        callbackUrl: data?.callbackUrl || window.location.href,
        orderId: data?.orderId
      }
    );
  }

  initiateTxn(data): Observable<any> {
    const locationInfo = JSON.parse((window as any)?.locationInfo || '{}');

    const payload: any = {
      amount: data?.amount,
      callbackUrl: data?.callbackUrl || window.location.href,
      location: locationInfo || {}
    };

    if ((window as any).isNativeApp) {
      payload.gatewayParams = {
        deviceContext: {
          deviceOS: 'ANDROID'
        },
        paymentInstrument: {
          type: 'PAY_PAGE'
        }
      };
    }

    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/transactions/initAddMoney`, {
        ...payload
      }
    );
  }

  verifyTxn(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/transactions/txnStatus`, {
        txnId: data?.txnId
      }
    );
  }

  verifyGiftCardTxn(data): Observable<any> {
    return this.customPostRequest(
      `${environment.giftCardsApiBase}/user/gift/transaction/txnStatus`, {
        txnId: data?.txnId
      }
    );
  }

  getTransactions(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/transactions/list`, {
        offset: data?.offset || null
      }
    );
  }

  getKycStatus(data): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/v1/transactions/kyc/status`, {}
    );
  }

  getKycDetails(data): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/v1/transactions/kyc/data`, {}
    );
  }

  updateKycDetails(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v1/transactions/kyc/data`, {...data}
    );
  }

  withdrawCheck(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('amount', data?.amount);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/transactions/withdrawCheck`, {params}
    );
  }

  withdrawWalletBalance(data): Observable<any> {
    const locationInfo = JSON.parse((window as any)?.locationInfo || '{}');

    return this.customPostRequest(
      `${environment.apiBase}/user/v1/transactions/withdraw`, {
        ...data,
        location: locationInfo || {}
      }
    );
  }

  createContest(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest`, {...data}
    );
  }

  updateContest(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contest?._id}`, {...data}
    );
  }

  getContestDetails(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contestId}`, {...data}
    );
  }

  getJoinedUsers(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contestId}/poolJoins/${data?.teamId}`, {...data}
    );
  }

  getAllJoinedUsers(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/contest/${data?.contestId}/leaderboard`, {...data}
    );
  }

  getFollowers(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('offset', data?.offset || 0);
    params = params.append('count', data?.count || 25);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/profile/follow/followers`, {params}
    );
  }

  getFollowing(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('offset', data?.offset || 0);
    params = params.append('count', data?.count || 25);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/profile/follow/following`, {params}
    );
  }

  getFantasyScores(data: any): Observable<any> {
    return this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/match/${data?.matchId}/fantasy`, {...data}
    );
  }

  getPlayersInfo(data: any): Observable<any> {
    const filteredPlayerIds = data?.playersIds?.filter(playerId => !this.global.players?.[playerId]);

    if (!filteredPlayerIds?.length) {
      return;
    }

    const allPlayers = [];
    this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/players/list`, {list: filteredPlayerIds}
    ).subscribe(res => {
      res?.result?.map(player => {
        if (player) {
          player.type = player?.roles?.includes('keeper') ? 'wk' : player?.skills?.length > 1 ? 'all' : player?.skills?.[0];
          allPlayers.push(player);
        }
      });
      allPlayers?.map(player => {
        this.global.players[player?._id] = player;
      });

      return allPlayers;
    }, (err: HttpErrorResponse) => {
    });
  }

  createTeam(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/team`, {team: data}
    );
  }

  updateTeam(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/team/${data?._id}`, {team: data}
    );
  }

  getTeams(data: any): Observable<any> {
    const filteredTeamIds = data?.teamIds?.filter(teamId => data?.invalidate || !this.global.teams?.[teamId]);

    if (!filteredTeamIds?.length) {
      return;
    }

    let params = new HttpParams();
    filteredTeamIds?.map(teamId => {
      params = params.append('id', teamId);
    });

    this.customGetRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/team`, {params}
    ).subscribe(res => {
      res?.result?.map(team => {
        if (team?._id) {
          this.global.teams[team?._id] = team;
        }
      });
    }, (err: HttpErrorResponse) => {
    });
  }

  initiateTransaction(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/payments/initiateTransaction`, {...data}
    );
  }

  verifyTransaction(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/payments/verifyTransaction`, {...data}
    );
  }

  getWinningsData(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('sourceType', 'challengeEntry');
    params = params.append('offset', data?.offset);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/rewards`, {params}
    );
  }

  getCommissionsData(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('sourceType', 'challengeCommission');
    params = params.append('offset', data?.offset);

    return this.customGetRequest(
      `${environment.apiBase}/user/v1/rewards`, {params}
    );
  }

  getNewResultsData(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/v1/contest/notification`, {...data}
    );
  }

  getBase64Path(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('imgUrl', data?.imgUrl);

    return this.customGetRequest(
      `${environment.baseUrl}/api/utils/getBase64Path`, {params}
    );
  }

  getMonthlyWinningsLeaderboard(data: any = {}): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/leaderboard/month/winnings`, {
        year: 2023,
        month: data?.monthIndex,
        date: 1
      }
    );
  }

  getMonthlyCommissionsLeaderboard(data: any = {}): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.apiConditionalPath()}/leaderboard/month/commissions`, {
        year: 2023,
        month: data?.monthIndex,
        date: 1
      }
    );
  }

  uploadPlayerPhoto(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/wh/uploads/player/${data?.playerId}/profilePhoto/`, data.formData, {
        headers: {
          Authorization: `Basic ${btoa(unescape(encodeURIComponent('nikhil:avdskj&^TWFVb')))}`
        }
      }
    );
  }

  uploadApk(data): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/wh/uploads/android/apk/`, data.formData, {
        headers: {
          Authorization: `Basic ${btoa(unescape(encodeURIComponent('komal:zv38fv@ak38X&Wsv')))}`
        }
      }
    );
  }

  uploadProfilePhoto(data): Observable<any> {
    return this.customPostRequest(`${environment.apiBase}/user/v1/me/profilePicture/`, data.formData);
  }

  getVerifiedCreators(data: any): Observable<any> {
    return this.customPostRequest(
      `${environment.apiBase}/user/${this.user.loginStatus ? 'v2' : 'public'}/contest/creatorsList/`, {
        tags: data?.matchIds || []
      }
    );
  }
}
