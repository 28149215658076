import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) {
    this.init().then();
  }

  init = async () => {
    this._storage = await this.storage.create();
  };

  setItem = async (key, value) => {
    if (!this._storage) {
      await this.init();
    }

    await this._storage?.set(key, value);
  };

  getItem = async (key) => {
    if (!this._storage) {
      await this.init();
    }

    const value = await this._storage?.get(key);
    if (this.isJsonString(value)) {
      return JSON.parse(value);
    }
    return value;
  };

  removeItem = async (key) => {
    if (!this._storage) {
      await this.init();
    }

    await this._storage?.remove(key);
  };

  isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
}
