import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-contest-filters',
  templateUrl: './contest-filters.component.html',
  styleUrls: ['./contest-filters.component.scss'],
})
export class ContestFiltersComponent implements OnInit {
  @Input() appliedFiltersInit;
  @Output() applyFilters = new EventEmitter<any>();
  @Output() closeBS = new EventEmitter<boolean>();

  appliedFilters: any = {
    entryFee: [],
    prizePool: []
  };
  entryFeeFilters = [
    {
      label: '₹1 - ₹10',
      start: 1,
      end: 10
    },
    {
      label: '₹11 - ₹100',
      start: 11,
      end: 100
    },
    {
      label: '₹101 - ₹1000',
      start: 101,
      end: 1000
    }
  ];
  prizePoolFilters = [
    {
      label: '₹10 - ₹100',
      start: 10,
      end: 100
    },
    {
      label: '₹101 - ₹1000',
      start: 101,
      end: 1000
    },
    {
      label: '₹1001 - ₹10000',
      start: 1001,
      end: 10000
    }
  ];

  constructor() {
  }

  ngOnInit() {
    if (this.appliedFiltersInit) {
      this.appliedFilters = this.appliedFiltersInit;
    }
  }

  closeModal = () => {
    this.closeBS.emit(true);
  };

  toggleFilter = (key, item) => {
    const index = this.appliedFilters?.[key]?.findIndex(filter => (filter?.start === item?.start && filter?.end === item?.end));
    if (index >= 0) {
      this.appliedFilters?.[key].splice(index, 1);
    } else {
      this.appliedFilters?.[key].push(item);
    }
  };

  isFilterSelected = (key, item) => {
    const index = this.appliedFilters?.[key]?.findIndex(filter => (filter?.start === item?.start && filter?.end === item?.end));
    return (index !== -1);
  };

  clearAllFilters = () => {
    this.appliedFilters = {
      entryFee: [],
      prizePool: []
    };
  };

  onFiltersApply = () => {
    this.applyFilters.emit(this.appliedFilters);
  };
}
