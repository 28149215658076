import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {AnalyticsService} from '../../services/analytics.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showInfoBs = false;

  constructor(
    private modalCtrl: ModalController,
    public user: UserService,
    private router: Router,
    public analytics: AnalyticsService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {
  }

  openLoginModal = async () => {
    this.analytics.trackEvent('header_wallet_click');

    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      cssClass: 'loginModal'
    });
    modal.present().then();

    // const {data, role} = await modal.onWillDismiss();
    // console.log(data, role);
  };

  goToHomePage = () => {
    this.analytics.trackEvent('header_logo_click');
    this.router.navigate(['/contests/']).then();
  };
}
