import {Component, HostListener, Inject, OnInit, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from './services/global.service';
import firebase from 'src/lib/firebase';
import {ApiService} from './services/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from './services/user.service';
import {SwUpdate} from '@angular/service-worker';
import {environment} from 'src/environments/environment';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaService} from './services/meta.service';
import {AnalyticsService} from './services/analytics.service';
import {StatusBar} from '@capacitor/status-bar';
import {StorageService} from './services/storage.service';
import {IonRouterOutlet, isPlatform, Platform} from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isFirebaseInitialised = false;
  showAppUpdateBS = false;
  showNotificationWidget = false;
  showJoiningBonusBS = true;

  constructor(
    private translate: TranslateService,
    public global: GlobalService,
    private api: ApiService,
    public user: UserService,
    private swUpdate: SwUpdate,
    public router: Router,
    private storage: StorageService,
    private metaService: MetaService,
    private titleService: Title,
    public analytics: AnalyticsService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    @Optional() private routerOutlet?: IonRouterOutlet
  ) {
    this.route.queryParams.subscribe(res => {
      this.analytics.utmParams = {
        utmCampaign: res.utm_campaign,
        utmMedium: res.utm_medium,
        utmSource: res.utm_source
      };

      this.analytics.sharedByUserId = res?.by;

      if ((window as any)?.fcmToken) {
        this.user.fcmToken = (window as any)?.fcmToken;
        this.storage.setItem('fcmToken', this.user.fcmToken).then();
      }
    });

    const deeplinkSeparator = `/link=${environment.baseUrl}`;
    if (window.location.href?.includes(deeplinkSeparator)) {
      const redirectToRoute = window.location.href?.split(deeplinkSeparator)?.[1];
      router.navigate([redirectToRoute], {replaceUrl: true, queryParamsHandling: 'merge'}).then();
    }

    this.initialiseApp().then(async () => {
      mixpanel.init('cb4420eef1812da3158d466ad51153d3');

      this.analytics.setUserPropertiesForAnalytics();

      this.analytics.trackEvent('pwaInitialised');

      this.handleRouteEvents();

      setInterval(async () => {
        this.refreshToken().then();
      }, 1800000); // 1800 sec = 30min

      this.swUpdate.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${event.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${event.currentVersion.hash}`);
            console.log(`New app version ready for use: ${event.latestVersion.hash}`);
            // this.showAppUpdateBS = true;
            this.onUpdateClick();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
            break;
        }
      });

      if (environment.production) {
        setTimeout(() => {
          this.swUpdate.checkForUpdate().then();
        }, 5000);

        setInterval(() => {
          this.swUpdate.checkForUpdate().then();
        }, 900000); // 600 sec = 10min
      }

      firebase.auth().onAuthStateChanged(this.authStateChanged);
    });
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      const angularThis = this;
      setTimeout(() => {
        this.platform.backButton.subscribeWithPriority(9999, () => {
          document.addEventListener('backbutton', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!angularThis.routerOutlet.canGoBack() && !['', '/'].includes(angularThis.router.url?.split('?')?.[0])) {
              angularThis.router.navigate(['/contests/']).then();
            }
          }, false);
        });
      }, 1000);
    });
  }

  showSkeletonLoading = () =>
    window.location.href?.indexOf('home.playlo.in') === -1 && window.location.href?.indexOf('/landing') === -1 &&
    window.location.href?.indexOf('creator.playlo.in') === -1 && window.location.href?.indexOf('/creator') === -1 &&
    window.location.href?.indexOf('highfox') === -1 && window.location.href?.indexOf('/gift-cards') === -1;

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showNotificationWidget = false;
      }
    });
  }

  refreshToken = async () => {
    this.user.refreshToken = await this.storage.getItem('refresh_token');
    if (this.user.refreshToken) {
      const authState = await firebase?.auth()?.currentUser;
      if (authState) {
        authState.token = await firebase?.auth()?.currentUser?.getIdToken(true);
        await this.setTokens(authState);
      }
    }
  };

  authStateChanged = async (authState) => {
    if (!authState) {
      this.isFirebaseInitialised = true;
      return;
    }

    authState.token = await authState.getIdToken();
    await this.setTokens(authState);

    setTimeout(() => {
      this.api.getUserData({}).subscribe(res => {
        this.user.loginObserver.next(true);
        this.user.profileObserver.next(res?.result);

        this.analytics.setUserPropertiesForAnalytics();

        this.getUserBalance();
        this.getNewResultsData();

        if (this.global.developerUserIds.includes(this.user.userObj?._id)) {
          (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
            action: 'allowDebugging'
          }));
        }
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
    }, 1000);
  };

  setTokens = async (authState) => {
    const {token, refreshToken} = authState;

    await this.storage.setItem('access_token', token);
    await this.storage.setItem('refresh_token', refreshToken);
    await this.storage.setItem('accessTokenSavedAt', Date.now());

    this.user.accessToken = token;
    this.user.refreshToken = refreshToken;
    this.user.accessTokenSavedAt = Date.now();

    if (token) {
      this.user.loginObserver.next(true);
      this.isFirebaseInitialised = true;
    }

    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
      action: 'tokens',
      accessToken: this.user.accessToken,
      refreshToken: this.user.refreshToken
    }));
  };

  getUserBalance = () => {
    this.api.getUserBalance({}).subscribe(res => {
      this.user.walletBalance = res?.result?.balance?.balance || 0;
    }, (err: HttpErrorResponse) => {
      console.log(err);
    });
  };

  initialiseApp = async () => {
    this.user.accessToken = await this.storage.getItem('access_token');
    if (!this.user.accessToken) {
      this.isFirebaseInitialised = true;
    }

    if (isPlatform('capacitor')) {
      await StatusBar.setBackgroundColor({color: '#090909'});

      await SplashScreen.hide({
        fadeOutDuration: 200
      });
    }

    const isTokenExpired = await this.user.isTokenExpired();
    if (isTokenExpired) {
      await this.refreshToken();
    }
  };

  globalize = (lang) => {
    this.translate.setDefaultLang('en');
    this.translate.use(lang || 'en');
  };

  onBSWrapperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onUpdateClick = () => {
    this.showAppUpdateBS = false;
    window.location.reload();
  };

  getNewResultsData = () => {
    this.showNotificationWidget = false;
    this.api.getNewResultsData({}).subscribe((res: any) => {
      if (res?.result?.won || res?.result?.pending || res?.result?.lost) {
        this.showNotificationWidget = true;
      } else {
        this.showNotificationWidget = false;
      }
    }, (err: HttpErrorResponse) => {
    });
  };

  onNotificationWidgetClick = () => {
    this.showNotificationWidget = false;
    this.analytics.trackEvent('home_notificationWidget_clicked');
    this.router.navigate(['/profile/' + this.user.userObj?._id], {
      queryParams: {
        tab: 'participated',
        subTab: 'complete'
      }
    }).then();
  };

  // addNotificationListeners = async () => {
  //   await PushNotifications.addListener('registration', async token => {
  //     if (token?.value) {
  //       this.user.fcmToken = token?.value;
  //       await this.storage.setItem('fcmToken', token?.value);
  //     }
  //   });
  //
  //   await PushNotifications.addListener('registrationError', err => {
  //     console.error('Registration error: ', err?.error);
  //   });
  //
  //   await PushNotifications.addListener('pushNotificationReceived', notification => {
  //     console.log('Push notification received: ', notification);
  //     // notification.title, notification.body, notification.data = {}
  //   });
  //
  //   await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
  //     console.log('Push notification action performed', notification?.actionId, notification?.inputValue);
  //   });
  // };
  //
  // registerNotifications = async () => {
  //   let permStatus = await PushNotifications.checkPermissions();
  //
  //   if (permStatus.receive === 'prompt') {
  //     permStatus = await PushNotifications.requestPermissions();
  //   }
  //
  //   if (permStatus.receive !== 'granted') {
  //     throw new Error('User denied permissions!');
  //   }
  //
  //   await PushNotifications.register();
  // };
  //
  // getDeliveredNotifications = async () => {
  //   const notificationList = await PushNotifications.getDeliveredNotifications();
  //   console.log('delivered notifications', notificationList);
  // };

  onJoiningBonusNext = () => {
    this.showJoiningBonusBS = false;
  };

  onDownloadAppClick = () => {
    this.analytics.trackEvent('appDownloadPrompt_download_clicked');
    location.href = this.global.getAppDownloadLink();
    this.global.showDownloadAppBS = false;
  };

  onUpgradeAppClick = () => {
    this.analytics.trackEvent('appUpgradePrompt_download_clicked');
    location.href = this.global.getAppUpgradeLink();
    this.global.showDownloadAppBS = false;
  };

  @HostListener('window:refreshUserBalance', ['$event'])
  onGetLocation(): void {
    console.log('refreshUserBalance');
    this.getUserBalance();
  }
}
