import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, NEVER, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import firebase from 'src/lib/firebase';
import {UserService} from '../services/user.service';
import {StorageService} from '../services/storage.service';
import {ApiService} from '../services/api.service';
import {Router} from '@angular/router';
import {GlobalService} from '../services/global.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  accessToken = '';

  constructor(
    private user: UserService,
    private storage: StorageService,
    private api: ApiService,
    private router: Router,
    private global: GlobalService
  ) {
    this.init().then();
  }

  init = async () => {
    this.accessToken = await this.getToken();
  };

  getToken = async () => {
    this.accessToken = await this.storage.getItem('access_token');
    return this.accessToken;
  };

  bypassToken = (req) => req?.url?.indexOf('ipwhois.app') !== -1 || req?.url?.indexOf('wh/uploads') !== -1;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleRequest(req, next)) as Observable<HttpEvent<any>>;
  }

  async handleRequest(req: HttpRequest<any>, next: HttpHandler) {
    this.accessToken = await this.getToken();
    const isTokenExpired = await this.user.isTokenExpired();

    if (isTokenExpired) {
      this.isRefreshingToken = true;
      this.accessToken = await this.fetchNewToken();
      this.isRefreshingToken = false;
    }

    let modifiedHeaders = req.headers.set('Authorization', `Bearer ${this.accessToken}`);

    if (req?.url?.indexOf('playlo.in') !== -1) {
      modifiedHeaders = modifiedHeaders.set('x-device-id', (window as any)?.deviceId?.toString() || '')
        .set('x-device-os', (window as any)?.deviceOS?.toString() || '')
        .set('x-device-model', (window as any)?.deviceModel?.toString() || '')
        .set('x-device-os-version', (window as any)?.deviceOSVersion?.toString() || '')
        .set('x-app-version', (window as any)?.appVersion?.toString() || '')
        .set('x-app-build-number', (window as any)?.appBuildNumber?.toString() || '')
        .set('x-device-bundle', (window as any)?.packageName?.toString() || '');
    }

    const modifiedReq = req.clone(this.bypassToken(req) ? {} : {
      headers: modifiedHeaders
    }).clone(this.bypassToken(req) ? {} : {
      withCredentials: true
    });

    return next.handle(modifiedReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              return from(this.handleInvalidUserError(modifiedReq, next));
            // case 404:
            //   return from(this.handle404Error(modifiedReq, next));
            case 500: {
              return from(this.handleTokenExpiredError(modifiedReq, next));
            }
            default:
              return throwError(err);
          }
        } else {
          return throwError(err);
        }
      })
    ).toPromise();
  }

  async handleInvalidUserError(req: HttpRequest<any>, next: HttpHandler) {
    if (this.user.isLoggedIn()) {
      this.logOut().then();
    }
    this.global.showLoader = false;
    return NEVER?.toPromise();
  }

  async handle404Error(req: HttpRequest<any>, next: HttpHandler) {
    // this.router.navigate(['/contests/']).then();
    this.global.showLoader = false;
    return NEVER?.toPromise();
  }

  async handleTokenExpiredError(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      const newToken = await this.fetchNewToken();
      if (newToken) {
        const modifiedReq = req.clone(this.bypassToken(req) ? {} : {
          headers: req.headers.set('Authorization', `Bearer ${newToken}`),
        }).clone(this.bypassToken(req) ? {} : {
          withCredentials: true
        });

        this.isRefreshingToken = false;
        return next.handle(modifiedReq).toPromise();
      } else {
        this.isRefreshingToken = false;
        this.global.showLoader = false;
        return NEVER?.toPromise();
      }
    } else {
      this.global.showLoader = false;
      return NEVER?.toPromise();
    }
  }

  async fetchNewToken() {
    const authState = await firebase?.auth()?.currentUser;
    if (!authState) {
      this.isRefreshingToken = false;
      this.user.loginObserver.next(false);
      // window.location.reload();
      return null;
    }
    authState.token = await firebase?.auth()?.currentUser?.getIdToken(true);
    const {token, refreshToken} = authState;
    await this.storage.setItem('access_token', token);
    await this.storage.setItem('refresh_token', refreshToken);
    await this.storage.setItem('accessTokenSavedAt', Date.now());

    this.user.accessToken = token;
    this.user.refreshToken = refreshToken;
    this.user.accessTokenSavedAt = Date.now();

    return token;
  };

  logOut = async () => {
    try {
      await firebase.auth().signOut().then(() => {
        this.user.loginObserver.next(false);
        this.api.logOut().subscribe(res => {
          this.router.navigate(['/contests/'], {replaceUrl: true});
        }, (err: HttpErrorResponse) => {
          this.router.navigate(['/contests/'], {replaceUrl: true});
        });
      }).catch(() => {
        this.user.loginObserver.next(false);
      });
    } catch (error) {
      this.user.loginObserver.next(false);
    }
  };
}
