import {Injectable} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import * as moment from 'moment';
import {AnalyticsService} from './analytics.service';
import {Router} from '@angular/router';
import {Share} from '@capacitor/share';
import {UserService} from './user.service';
import base64 from 'base-64';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  showLoader = false;
  metaTitle = 'Play Gamified Fantasy Sports & Win Cash Prizes on PlayLo App | PlayLo.in';
  metaDesc = 'Play customised Fantasy sports games with unique Gamification. Download the PlayLo app and win Cash Prizes. Earn up to ₹50 cash bonus! Select a Match > Choose your Fantasy Team > Join Contests';
  metaImg = 'https://playlo.in/assets/images/logo-square.png';
  teams: any = {};
  players: any = {};
  selectedMatchId = '';
  playerTypes = {
    wk: {
      icon: '/assets/icons/keeper-icon-2.svg'
    },
    bat: {
      icon: '/assets/icons/batsman-icon-2.svg'
    },
    all: {
      icon: '/assets/icons/allrounder-icon-2.svg'
    },
    bowl: {
      icon: '/assets/icons/bowler-icon-3.svg'
    }
  };
  contestTypes = [
    {
      value: 'headonhead',
      label: 'Best player',
      maxPlayers: 1,
      maxCredits: 20,
      playerTypes: ['wk', 'bat', 'all', 'bowl'],
      boostersRequired: false
    },
    {
      value: 'playing2',
      label: 'Playing 2',
      maxPlayers: 2,
      maxCredits: 20,
      playerTypes: ['wk', 'bat', 'all', 'bowl'],
      boostersRequired: true
    },
    {
      value: 'batting2',
      label: 'Batting 2',
      maxPlayers: 2,
      maxCredits: 20,
      playerTypes: ['wk', 'bat', 'all'],
      boostersRequired: true
    },
    {
      value: 'bowling2',
      label: 'Bowling 2',
      maxPlayers: 2,
      maxCredits: 20,
      playerTypes: ['all', 'bowl'],
      boostersRequired: true
    },
    {
      value: 'playing3',
      label: 'Playing 3',
      maxPlayers: 3,
      maxCredits: 30,
      playerTypes: ['wk', 'bat', 'all', 'bowl'],
      boostersRequired: true
    },
    {
      value: 'batting3',
      label: 'Batting 3',
      maxPlayers: 3,
      maxCredits: 30,
      playerTypes: ['wk', 'bat', 'all'],
      boostersRequired: true
    },
    {
      value: 'bowling3',
      label: 'Bowling 3',
      maxPlayers: 3,
      maxCredits: 30,
      playerTypes: ['all', 'bowl'],
      boostersRequired: true
    },
    {
      value: 'playing5',
      label: 'Playing 5',
      maxPlayers: 5,
      maxCredits: 50,
      playerTypes: ['wk', 'bat', 'all', 'bowl'],
      boostersRequired: true
    },
    {
      value: 'batting5',
      label: 'Batting 5',
      maxPlayers: 5,
      maxCredits: 50,
      playerTypes: ['wk', 'bat', 'all'],
      boostersRequired: true
    },
    {
      value: 'playing11',
      label: 'Playing 11',
      maxPlayers: 11,
      maxCredits: 100,
      playerTypes: ['wk', 'bat', 'all', 'bowl'],
      boostersRequired: true
    },
  ];
  txnTypes = {
    credit: ['credit', 'debit_refund'],
    debit: ['debit']
  };
  txnStatuses = {
    success: ['success', 'refund_processed', 'processed']
  };
  withdrawStatuses = {
    success: 'success',
    failed: 'failed'
  };
  matchStates = {
    notStarted: 'not_started',
    active: 'active',
    completed: 'completed'
  };
  matchPlayStatuses = {
    inPlay: 'in_play',
  };
  contestStates = {
    active: 'active',
    complete: 'complete',
    cancelled: 'canceled'
  };
  contestEntryStates = {
    active: 'active',
    waitingResult: 'waiting_result',
    draw: 'draw',
    success: 'success',
    fail: 'fail',
    archive: 'archive',
    delete: 'delete',
    stale: 'stale'
  };
  contestCompletionEntryStates = [
    this.contestEntryStates.draw,
    this.contestEntryStates.success,
    this.contestEntryStates.fail,
    this.contestEntryStates.archive,
    this.contestEntryStates.delete,
    this.contestEntryStates.stale
  ];
  kycStates = {
    notDone: 'not_done',
    initiated: 'initiated',
    inReview: 'in_review',
    approved: 'approved',
    rejected: 'rejected',
  };
  gamingRestrictedStates = ['Telangana', 'Assam', 'Nagaland', 'Odisha', 'Andhra Pradesh', 'Sikkim'];
  randMemeNum = 1;
  matches: any = {
    list: [],
    updatedAt: null
  };
  allMatches: any = {};
  walkthrough: any = {
    homeContests: false,
    teamSelection: false
  };
  showDownloadAppBS = false;
  developerUserIds = [
    // 'VLni287uHBfMaAUy1jC9OwgMGsh1', // Rahul
    '8BWKi1mMk6hSsMkTzVuCfXMbtjN2', // Bhavesh
    'B6QPmphoRWgbPH4GXKw4hpAA3993', // Komal
    // 'F4heBCml7hfBZzdfavYo5dCwRpx1', // Nikhil
    // 'DvDjxmB9BWWUWKCLnMF18OZrVem2', // Srinjan
    // 'YBLVwU5PZGfdBwunPKIp6yVOOf63' // Abhijit
  ];
  minEntryFee = 10;
  maxEntryFee = 1000;
  minSlots = 100;
  maxSlots = 1000;
  verifiedCreators: any = {};
  selectedCreators: any = {};
  joinedContests: any = {};
  upcomingContests: any = {};
  publishedContests: any = {};
  unPublishedContests: any = {};
  contests: any = {};
  collapseJoinedContests = false;


  constructor(
    private toastController: ToastController,
    private analytics: AnalyticsService,
    private router: Router,
    private platform: Platform,
    private user: UserService
  ) {
    this.randMemeNum = Math.ceil(Math.random() * 5);
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      position: 'bottom'
    });

    await toast.present();
  }

  isInsideWebview = () => this.isRNApp() || (window as any)?.navigator?.userAgent === 'Android App' || this.user.fcmToken;

  isRNApp = () => (window as any)?.isNativeApp;

  isUsingOlderVersion = () => (window as any)?.isNativeApp;

  getPlayerName = (playerId) => this.players?.[playerId]?.jersey_name;

  getPlayerImg = (playerId) => this.players?.[playerId]?.photoURL || '';

  getTeamImg = (team) => {
    const teamId = typeof team === 'string' ? team : team?.id;
    return teamId ? `https://myccbee.s3.ap-south-1.amazonaws.com/playlo/${teamId}.png?v=2` : '';
  };

  getTeamPlayersInOrder = (teamId) => {
    const captain = this.teams?.[teamId]?.lineup?.captain;
    const viceCaptain = this.teams?.[teamId]?.lineup?.viceCaptain;
    return this.teams?.[teamId]?.lineup?.players?.sort((p1, p2) => {
      if (p1 === captain || (p1 === viceCaptain && p2 !== captain)) {
        return -1;
      }
      return 0;
    });
  };

  createArray = (count) => {
    const data = [];
    for (let i = 0; i < count; i++) {
      data.push({});
    }
    return data;
  };

  fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  copyTextToClipboard = (text, toastMsg = '') => {
    if (!navigator?.clipboard || this.isInsideWebview()) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      console.log('Async: Copying to clipboard was successful!');

      if (toastMsg) {
        this.showToast(toastMsg).then();
      }
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  };

  isPrizeMoneyFinal = (contest) => moment(contest?.operateOptions?.registrationTime).valueOf() < moment().valueOf();

  isContestRegistrationEnded = (contest) => moment(contest?.operateOptions?.registrationTime).valueOf() < moment().valueOf();

  isContestCompleted = (contest) => contest?.entry ? this.contestCompletionEntryStates.includes(contest?.entry?.state) : (moment(contest?.operateOptions?.endTime).valueOf() < moment().valueOf());

  isContestLive = (contest) => this.isContestStarted(contest) && !this.isContestCompleted(contest);

  isContestJoined = (contest) => contest?.entry ? true : false;

  isContestCreator = (contest, userId = this.user.userObj._id) => contest?.creator?.id === userId;

  isContestFull = (contest) => contest?.flags?.isFull;

  getPrizePool = (contest) => {
    let poolAmount = 0;
    if (this.isPrizeMoneyFinal(contest)) {
      poolAmount = (contest?.reward?.poolValue || contest?.joinOptions?.costs?.[0]?.currencyValue) * (contest?.arenaInfo?.count || 0);
    } else {
      // TODO: Remove hardcoded estimatedGroupSize=100
      poolAmount = (contest?.reward?.poolValue || contest?.joinOptions?.costs?.[0]?.currencyValue) * Math.max(100, (contest?.arenaInfo?.count || 0));
      // poolAmount = (contest?.reward?.poolValue || contest?.joinOptions?.costs?.[0]?.currencyValue) * Math.max(contest?.joinOptions?.groupingData?.estimatedGroupSize, (contest?.arenaInfo?.count || 0));
    }

    return this.formatNumber(poolAmount);
  };

  isMatchStartTimePassed = (match) => moment(match?.start_at).valueOf() < moment().valueOf();

  isMatchStarted = (match) => match?.play && moment(match?.start_at).valueOf() < moment().valueOf();

  isMatchCompleted = (match) => match?.status === this.matchStates.completed;

  isContestStarted = (contest) => moment(contest?.operateOptions?.startTime).valueOf() < moment().valueOf();

  isContestEnded = (contest) => moment(contest?.operateOptions?.endTime).valueOf() < moment().valueOf();

  getMyTeamId = (contest) => {
    const originId = contest?.operateOptions?.targets?.[0]?.originId;
    return contest?.entry?.extraData?.joinParams?.[originId]?.choice;
  };

  getMyTeamScore = (contest, teamId = null) => {
    const myTeamId = teamId || this.getMyTeamId(contest);
    return this.teams?.[myTeamId]?.performance?.fantasyScore || 0;
  };

  isWinning = (contest, teamId = null) => {
    if (!teamId) {
      teamId = this.getMyTeamId(contest);
    }

    let highestTeamScore = 0;
    contest?.arenaInfo?.pool?.forEach(item => {
      if (this.teams?.[item?.id]?.performance?.fantasyScore > highestTeamScore) {
        highestTeamScore = this.teams?.[item?.id]?.performance?.fantasyScore;
      }
    });

    const winningTeamIds = contest?.arenaInfo?.pool?.filter(item => this.teams?.[item?.id]?.performance?.fantasyScore === highestTeamScore) || [];

    if (teamId === this.getMyTeamId(contest) && contest?.entry?.state === this.contestEntryStates.fail) {
      return false;
    } else if (contest?.state === this.contestStates.cancelled) {
      return false;
    }

    return winningTeamIds?.find(item => item?.id === teamId) && (highestTeamScore > 0 || moment().diff(moment(contest?.operateOptions?.startTime), 'minutes') > 15);
  };

  isAnyBowlerBowled = (match) => {
    const isAnyTeam1BowlerBowled = this.getPlayersList(match, 'a')?.find(playerId => this.getPlayerStats(match, playerId, 'bowling')?.overs?.[0] || this.getPlayerStats(playerId, 'bowling')?.overs?.[1] || playerId === match?.play?.live?.bowler_key);
    const isAnyTeam2BowlerBowled = this.getPlayersList(match, 'b')?.find(playerId => this.getPlayerStats(match, playerId, 'bowling')?.overs?.[0] || this.getPlayerStats(playerId, 'bowling')?.overs?.[1] || playerId === match?.play?.live?.bowler_key);
    return isAnyTeam1BowlerBowled || isAnyTeam2BowlerBowled;
  };

  getPlayerStats = (match, playerId, role = 'batting') => {
    const playerObj = match?.players?.[playerId];
    const inn1Score = {...playerObj?.score?.['1']?.[role]?.score};
    return inn1Score;
  };

  getPlayersList = (match, playingTeam = 'a') => {
    const playingSquad = match?.squad?.[playingTeam]?.playing_xi || [];
    match?.play?.innings?.[playingTeam + '_1']?.batting_order?.map(playerId => {
      if (!playingSquad?.includes(playerId)) {
        playingSquad.push(playerId);
      }
    });
    match?.play?.innings?.[playingTeam + '_1']?.bowling_order?.map(playerId => {
      if (!playingSquad?.includes(playerId)) {
        playingSquad.push(playerId);
      }
    });
    return playingSquad;
  };

  getMyWinnings = (contest, teamId = null) => {
    let winningAmount = 0;
    if (!teamId) {
      teamId = this.getMyTeamId(contest);
    }

    let totalWinningPlayers = 0;
    contest?.arenaInfo?.pool?.filter(item => {
      if (this.isWinning(contest, item?.id)) {
        totalWinningPlayers += (item?.joined || 0);
      }
    });

    if (this.isWinning(contest, teamId)) {
      const totalJoinedPlayers = contest?.arenaInfo?.count;
      const totalLosingPlayers = totalJoinedPlayers - totalWinningPlayers;
      const totalCommission = totalLosingPlayers * (contest?.reward?.poolValue || contest?.joinOptions?.costs?.[0]?.currencyValue) * contest?.reward?.fees?.total / 100;
      if (totalWinningPlayers === 0) {
        winningAmount = this.getPrizePool(contest) - totalCommission;
      } else {
        winningAmount = (this.getPrizePool(contest) - totalCommission) / totalWinningPlayers;
      }
    }

    return winningAmount && totalWinningPlayers ? this.formatNumber(winningAmount, 0) : 0;
  };

  getWinningMultiple = (contest) => {
    const entryFee = contest?.joinOptions?.costs?.[0]?.currencyValue;
    const winnings = this.getMyWinnings(contest);

    if (entryFee === 0) {
      return '';
    }
    return `+${this.formatNumber((winnings / entryFee), 1)}x`;
  };

  getInitials = (name) => {
    let initials = '';
    const nameArray = name?.split(' ');
    nameArray?.forEach(item => {
      if (initials?.length < 2 && item) {
        initials = initials + item?.[0];
      }
    });
    return initials;
  };

  isMobileBrowser = () => this.platform.is('mobileweb');

  isOnMobileDevice = () => this.platform.is('ios') || this.platform.is('android') || this.platform.is('mobileweb');

  generateMatchBase64Link = async (match) => {
    const matchId = match?._id;
    const link = await this.createBase64Link('/contests/', {matchId});
    return link;
  };

  generateMatchDynamicLink = async (match) => {
    const matchId = match?._id;
    const link = await this.createLongDynamicLink('/contests/', {matchId}, {
      st: `${match?.short_name || match?.title} ${match?.sub_title ? ` - ${match?.sub_title}` : ''} | PlayLo.in`,
      sd: match?.title
    });

    return link;
  };

  generateContestDynamicLink = async (contest, userId) => {
    const contestId = contest?._id;
    const link = await this.createLongDynamicLink(`/contests/${contestId}/`, {by: userId || ''}, {
      st: `${this.getContestName(contest, true)} - ${contest?.detailsToShow?.extraData?.teams?.a?.code} vs ${contest?.detailsToShow?.extraData?.teams?.b?.code} - Win upto ₹${this.getPrizePool(contest)} | PlayLo.in`,
      sd: contest?.detailsToShow?.description || contest?.detailsToShow?.name,
      si: contest?.detailsToShow?.media?.[0]?.url
    });

    return link;
  };

  onShareContest = async (contest, userId) => {
    const contestId = contest?._id;
    this.analytics.trackEvent('contest_shared', {contestId});

    const canShare = this.isOnMobileDevice() && await Share?.canShare();

    this.showLoader = true;
    const linkToShare = await this.generateContestDynamicLink(contest, userId);
    this.showLoader = false;

    const textToShare = `${this.getContestName(contest, true)} - ${contest?.detailsToShow?.extraData?.teams?.a?.code} vs ${contest?.detailsToShow?.extraData?.teams?.b?.code} - Win upto Rs${this.getPrizePool(contest)} - Join by ${moment(contest?.operateOptions?.registrationTime).format('DD MMM, hh:mm A')}`;

    if (this.isInsideWebview()) {
      if ((window as any)?.isNativeApp) {
        (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
          action: 'share',
          options: {
            title: `Join now to win upto Rs${this.getPrizePool(contest)}`,
            message: textToShare,
            url: linkToShare,
          }
        }));
      } else {
        location.href = `shareapp://sharetext?=${textToShare} ${linkToShare}`;
      }
    } else if (canShare) {
      await Share.share({
        title: `Join now to win upto Rs${this.getPrizePool(contest)}`,
        text: textToShare,
        url: linkToShare,
        dialogTitle: 'Share with fiends',
      });
    } else {
      window.open(`https://api.whatsapp.com/send?text=${textToShare} ${linkToShare}`);
    }
  };

  copyContestLink = async (contest, userId) => {
    this.showLoader = true;
    const contestId = contest?._id;
    this.analytics.trackEvent('contest_linkCopied', {contestId});
    const dynamicLink = await this.generateContestDynamicLink(contest, userId);
    this.copyTextToClipboard(dynamicLink);
    this.showToast('Contest link copied successfully!').then();
    this.showLoader = false;
  };

  generateProfileDynamicLink = async (profileData, userId) => {
    const profileId = profileData?._id;
    const profileName = profileData?.profile?.displayName;
    const link = await this.createLongDynamicLink(`/profile/${profileId}/`, {by: userId || ''}, {
      st: `${profileName}'s profile on PlayLo.in`,
      sd: this.metaDesc,
      si: profileData?.profile?.photoURL || this.metaImg
    });

    return link;
  };

  onShareProfile = async (profileData, userId) => {
    const profileId = profileData?._id;
    const profileName = profileData?.profile?.displayName;
    this.analytics.trackEvent('profile_shared', {profileId});

    const canShare = this.isOnMobileDevice() && await Share?.canShare();

    this.showLoader = true;
    const linkToShare = await this.generateProfileDynamicLink(profileData, userId);
    this.showLoader = false;

    const textToShare = `${profileName}'s profile on PlayLo.in`;

    if (this.isInsideWebview()) {
      if ((window as any)?.isNativeApp) {
        (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
          action: 'share',
          options: {
            title: textToShare,
            message: textToShare,
            url: linkToShare,
          }
        }));
      } else {
        location.href = `shareapp://sharetext?=${textToShare} ${linkToShare}`;
      }
    } else if (canShare) {
      await Share.share({
        title: textToShare,
        text: textToShare,
        url: linkToShare,
        dialogTitle: 'Share with fiends',
      });
    } else {
      window.open(`https://api.whatsapp.com/send?text=${textToShare}`);
    }
  };

  copyProfileLink = async (profileData, userId) => {
    this.showLoader = true;
    const profileId = profileData?._id;
    this.analytics.trackEvent('profile_linkCopied', {profileId});
    const dynamicLink = await this.generateProfileDynamicLink(profileData, userId);
    this.copyTextToClipboard(dynamicLink);
    this.showToast('Profile link copied successfully!').then();
    this.showLoader = false;
  };

  getFirstPlayingTeamDetails = (match) => {
    const firstBatting = match?.play?.first_batting || 'a';

    return {
      index: firstBatting,
      code: match?.teams?.[firstBatting]?.code,
      id: match?.teams?.[firstBatting]?.id,
      name: match?.teams?.[firstBatting]?.name
    };
  };

  getSecondPlayingTeamDetails = (match) => {
    const secondBatting = match?.play?.first_batting === 'b' ? 'a' : 'b';

    return {
      index: secondBatting,
      code: match?.teams?.[secondBatting]?.code,
      id: match?.teams?.[secondBatting]?.id,
      name: match?.teams?.[secondBatting]?.name
    };
  };

  formatPercentage = (num) => {
    if (Number.isInteger(num)) {
      return num;
    } else {
      return num?.toFixed(1);
    }
  };

  formatNumber = (num, decimalPlaces = 2) => {
    if (Number.isInteger(num)) {
      return num;
    }
    return num?.toFixed(decimalPlaces);

    // const numberFormat: any = new Intl.NumberFormat('en-IN');
    //
    // if (Number.isInteger(num)) {
    //   return numberFormat?.format(num);
    // }
    // return numberFormat?.format(num?.toFixed(decimalPlaces));
  };

  getContestTypeLabel = (contest) => {
    const contestType = contest?.detailsToShow?.targetSummary;
    const contestName = this.contestTypes?.find(item => item.value === contestType)?.label || contestType;
    return contestName;
  };

  getContestName = (contest, forMeta = false) => {
    const contestType = contest?.detailsToShow?.targetSummary;
    const contestNamePrefix = contest?.entry && !forMeta ? '' : 'Choose your ';
    const contestName = this.contestTypes?.find(item => item.value === contestType)?.label || contestType;
    return contestNamePrefix + contestName;
  };

  scrollActiveMatchHorizontally = (parentId, selectedMatchIndex) => {
    const matchWidgetWidth = 200;
    const matchWidgetsGap = 16;
    const parentSTartPadding = 16;
    const windowWidth = window.innerWidth > 500 ? 500 : window.innerWidth;
    this.scrollHorizontally(parentId, selectedMatchIndex * (matchWidgetWidth + matchWidgetsGap) + parentSTartPadding - (windowWidth - matchWidgetWidth) / 2);
  };

  scrollHorizontally = (parentId, position = 0) => {
    const parent = document.getElementById(parentId);
    if (parent) {
      parent.scrollLeft = position || 0;
    }
  };

  scrollToElementHorizontally = (elId, parentId) => {
    const el = document.getElementById(elId);
    const parent = document.getElementById(parentId);

    if (!el || !parent) {
      return;
    }

    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = parent.offsetLeft + parent.offsetWidth;

    // check if element not in view
    if (elLeft >= elParentLeft + parent.scrollLeft) {
      parent.scrollLeft = elLeft - elParentLeft;
    } else if (elLeft <= parent.offsetLeft + parent.scrollLeft) {
      parent.scrollLeft = el.offsetLeft - parent.offsetLeft;
    }
  };

  onCreatorClick = (creator, event, contest = {}) => {
    if (this.user.loginStatus) {
      event?.stopPropagation();
      event?.preventDefault();
      this.router.navigate(['/profile/' + creator?.id]).then();
    }
  };

  formatMatchTime = (timestamp) => moment(timestamp)?.format('MMM DD, hh:mm A');

  formatMatchDate = (timestamp) => moment(timestamp)?.format('MMM DD, YYYY');

  getRandomMeme = () => `/assets/images/memes/empty-state-${this.randMemeNum}.png`;

  triggerPushNotificationPermission = () => {
    setTimeout(() => {
      (window as any)?.OneSignal.push(() => {
        (window as any)?.OneSignal.showSlidedownPrompt();
      });
    }, 3000);
  };

  getMatchCode = (code) => {
    const teamCode = code?.code || code || '';

    if (typeof teamCode === 'string') {
      switch (teamCode?.toUpperCase()) {
        case 'MI':
          return 'MUM';
          break;
        case 'KKR':
          return 'KOL';
          break;
        case 'SRH':
          return 'HYD';
          break;
        case 'RCB':
          return 'BAN';
          break;
        case 'DC':
          return 'DEL';
          break;
        case 'LSG':
          return 'LUK';
          break;
        case 'PBKS':
          return 'PUN';
          break;
        case 'GT':
          return 'GUJ';
          break;
        case 'RR':
          return 'RAJ';
          break;
        case 'CSK':
          return 'CHN';
          break;
        default:
          return teamCode?.toUpperCase();
      }
    } else {
      return '';
    }
  };

  isIOSDevice = () => {
    if (typeof window !== 'undefined') {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          window.navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }

    return null;
  };

  getAppDownloadLink = () => 'https://playlo.page.link/apk';

  getAppUpgradeLink = () => 'https://playlo.page.link/download-apk';

  sortContests = (contests, match) => contests?.sort((a, b) => {
    if (!this.isMatchStarted(match)) {
      return (b?.arenaInfo?.count || 0) - (a?.arenaInfo?.count || 0);
    } else {
      if (this.isWinning(a) && !this.isWinning(b)) {
        return -1;
      } else if (!this.isWinning(a) && this.isWinning(b)) {
        return 1;
      } else if (!this.isWinning(a) && !this.isWinning(b)) {
        return (b?.arenaInfo?.count || 0) - (a?.arenaInfo?.count || 0);
      } else {
        return this.getMyWinnings(b) - this.getMyWinnings(a);
      }
    }
  });

  createBase64Link = async (
    pathname,
    params: any = {}
  ) => {
    const queryParamsString = new URLSearchParams({...params}).toString();
    const webRoute = `${pathname || '/'}?${queryParamsString}`;
    const webRedirectionLink = `https://playlo.in/link/${base64.encode(webRoute)}`;
    return webRedirectionLink;
  };

  createLongDynamicLink = async (
    pathname,
    params: any = {},
    meta: any = {}
  ) => {
    const packageName = 'in.playlo.app';
    const queryParamsString = new URLSearchParams({...params}).toString();
    const webRoute = `${pathname || '/'}?${queryParamsString}`;
    const webLink = `https://playlo.in${webRoute}`;
    const webRedirectionLink = `https://playlo.in/link/${base64.encode(webRoute)}`;
    const deeplink = (window as any)?.isNativeApp ? `https://playlo.in/?link=${webRedirectionLink}` : `https://playlo.in/link=${webRedirectionLink}`;
    const longDynamicLink = `https://playlo.page.link/?link=${encodeURIComponent(deeplink)}&apn=${packageName}&afl=${webLink}&st=${encodeURIComponent(meta?.st || this.metaTitle)}&sd=${encodeURIComponent(meta?.sd || this.metaDesc)}&si=${encodeURIComponent(meta?.si || this.metaImg)}`;

    // if (analytics.utm_source && analytics.utm_source != '') {
    //   deeplink += '&utm_source=' + encodeURIComponent(analytics.utm_source);
    // }
    // if (analytics.utm_medium && analytics.utm_medium != '') {
    //   deeplink += '&utm_medium=' + encodeURIComponent(analytics.utm_medium);
    // }
    // if (analytics.utm_campaign && analytics.utm_campaign != '') {
    //   deeplink += '&utm_campaign=' + encodeURIComponent(analytics.utm_campaign);
    // }

    const firebaseWebApiKey = 'AIzaSyAiCt8xlk8ir17CPkcaaUwSbCTFsZzSNRY';
    const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseWebApiKey}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        longDynamicLink
      })
    }).then(res => res?.json());

    console.log(webLink);
    console.log(webRedirectionLink);
    return response?.shortLink || longDynamicLink;
  };

  getMatchStatus = (match) => {
    switch (match?.status) {
      case 'not_started':
        return 'Delayed';
        break;
      default:
        return 'Live';
    }
  };

  getPlayStatus = (match) => {
    switch (match?.play_status) {
      case 'scheduled':
        return '';
        break;
      case 'start_delay':
        return 'Delayed';
        break;
      case 'active':
        return '';
        break;
      case 'result':
        return match?.play?.result?.msg || '';
        break;
      case 'rain_delay':
        return 'Delayed due to rain';
        break;
      case 'innings_break':
        return 'Innings break';
        break;
      default:
        return '';
    }
  };
}
