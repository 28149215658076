import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../../services/global.service';
import * as moment from 'moment';

@Component({
  selector: 'app-team-widget',
  templateUrl: './team-widget.component.html',
  styleUrls: ['./team-widget.component.scss'],
})
export class TeamWidgetComponent implements OnInit {
  @Input() isMyTeam = false;
  @Input() teamObj;
  @Input() teamIndex;
  @Input() contest;
  @Input() match;
  @Input() selectedTeamId;
  @Input() playerCredits;
  @Input() creation = false;
  @Input() fantasyScores;
  @Input() totalTeams = 0;
  @Input() allowEditing = false;
  @Output() selectTeamEvent = new EventEmitter<any>();
  @Output() choosePlayersEvent = new EventEmitter<any>();
  @Output() deleteTeamEvent = new EventEmitter<any>();
  @Output() openSubstitutesEvent = new EventEmitter<any>();

  constructor(
    public global: GlobalService
  ) {
  }

  ngOnInit() {
  }

  isEditable = () => (this.allowEditing || !this.contest?.entry) && !this.global.isContestRegistrationEnded(this.contest);

  selectTeam = (req) => {
    if (!this.creation) {
      this.selectTeamEvent.emit(req);
    }
  };

  choosePlayers = (req) => {
    if (this.creation) {
      this.choosePlayersEvent.emit(req);
    }
  };

  deleteTeam = (event, req) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.creation) {
      this.deleteTeamEvent.emit(req);
    }
  };

  getPlayerCredits = (playerId) => this.playerCredits?.[playerId]?.value || 0;

  getPlayerPoints = (playerId) => this.playerCredits?.[playerId]?.tournament_points || 0;

  getTeamCredits = (players) => players?.reduce((partialSum, playerId) => partialSum + this.getPlayerCredits(playerId), 0) || 0;

  getTeamPoints = (players) => players?.reduce((partialSum, playerId) => partialSum + this.getPlayerPoints(playerId), 0) || 0;

  isMatchStarted = () => moment(this.match?.start_at).diff(moment(), 'seconds') < 0;

  isPlaying = (playerId) => (!this.match?.squad?.a?.playing_xi?.length || !this.match?.squad?.b?.playing_xi?.length) ||
    this.match?.squad?.a?.playing_xi
      ?.concat(this.match?.squad?.b?.playing_xi)
      ?.concat(this.match?.play?.innings?.a_1?.batting_order)
      ?.concat(this.match?.play?.innings?.a_1?.bowling_order)
      ?.concat(this.match?.play?.innings?.b_1?.batting_order)
      ?.concat(this.match?.play?.innings?.b_1?.bowling_order)
      ?.includes(playerId);

  getFantasyScore = (playerId) => {
    let multiplier = 1;
    if (playerId === this.global.teams?.[this.teamObj?.id]?.lineup?.captain) {
      multiplier = 2;
    } else if (playerId === this.global.teams?.[this.teamObj?.id]?.lineup?.viceCaptain) {
      multiplier = 1.5;
    }
    return (this.fantasyScores?.[playerId]?.points || 0) * multiplier;
  };

  getJoinedByPercentage = (teamId) => {
    if (!this.contest?.arenaInfo?.count) {
      return 0;
    }

    return (this.contest?.arenaInfo?.pool?.find(item => item?.id === teamId)?.joined || 0) / this.contest?.arenaInfo?.count * 100;
  };

  getSubstitutePlayers = () => this.global.teams?.[this.teamObj?.id]?.lineup?.substitutes || [];

  onSubstituteWidgetClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const backupPlayers = this.getSubstitutePlayers();
    const playingBackupPlayers = backupPlayers?.filter(playerId => this.isPlaying(playerId)) || [];
    this.openSubstitutesEvent.emit({backupPlayers, playingBackupPlayers});
  };
}
