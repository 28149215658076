import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router, RouterEvent} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ModalController} from '@ionic/angular';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {GlobalService} from '../../services/global.service';
import {AnalyticsService} from '../../services/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() scrollToTop = new EventEmitter<boolean>();
  selectedPath = '';

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    public user: UserService,
    public global: GlobalService,
    public analytics: AnalyticsService
  ) {
    this.selectedPath = this.router.url;
    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event.url) {
        this.selectedPath = event.url;
      }
    });
  }

  ngOnInit() {
  }

  onHomeTabClick = () => {
    this.analytics.trackEvent('footer_homeTab_click');
    this.scrollToTop.emit(true);
    this.router.navigate(['/contests/']).then();
  };

  onCreateTabClick = () => {
    this.analytics.trackEvent('footer_createTab_click');
    if (this.user.isProfileComplete()) {
      this.router.navigate(['/created-contests']).then();
    } else {
      this.openLoginModal('/created-contests').then();
    }
  };

  onLeaderboardTabClick = () => {
    this.analytics.trackEvent('footer_leaderboardTab_click');
    if (this.user.isProfileComplete()) {
      this.router.navigate(['/leaderboard']).then();
    } else {
      this.openLoginModal('/leaderboard').then();
    }
  };

  onProfileTabClick = () => {
    this.analytics.trackEvent('footer_profileTab_click');
    if (this.user.isProfileComplete()) {
      this.router.navigate(['/profile/' + this.user?.userObj?._id]).then();
    } else {
      this.openLoginModal('/profile/' + this.user?.userObj?._id).then();
    }
  };

  openLoginModal = async (redirectToRoute = '/') => {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      cssClass: 'loginModal'
    });
    modal.present().then();

    const {data, role} = await modal.onWillDismiss();
    if (role === 'success' && redirectToRoute) {
      this.router.navigate([redirectToRoute]).then();
    }
  };
}
