import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {UserService} from '../../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-add-money',
  templateUrl: './add-money.component.html',
  styleUrls: ['./add-money.component.scss'],
})
export class AddMoneyComponent implements OnInit {
  @Output() closeBs = new EventEmitter<boolean>();
  @Input() initialAmountToAdd = 0;
  @Input() callbackUrl = '';
  amountToAdd = null;
  minAmountToAdd = 10;

  constructor(
    public global: GlobalService,
    public user: UserService,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    if (this.initialAmountToAdd > 0) {
      this.amountToAdd = +this.initialAmountToAdd;

      if (this.amountToAdd < this.minAmountToAdd) {
        this.amountToAdd = this.minAmountToAdd;
      }
    }

    this.loadPaytmScript();
  }

  loadPaytmScript = () => {
    if (!((window as any)?.Paytm && (window as any)?.Paytm?.CheckoutJS)) {
      const script = document.createElement('script');
      script.src = `${environment.paytmPgHost}/merchantpgpui/checkoutjs/merchants/${environment.paytmPgMid}.js`;
      document.head.appendChild(script);

      script.onload = () => {
      };
    }
  };

  onAmountChange = (event) => {
    this.amountToAdd = +(event.target as HTMLInputElement).value;
  };

  addMoney = () => {
    const locationInfo = JSON.parse((window as any)?.locationInfo || '{}');

    if ((window as any)?.isNativeApp && !locationInfo?.latitude) {
      (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
        action: 'getLocation'
      }));
    } else if (this.amountToAdd > 0) {
      if (this.amountToAdd < this.minAmountToAdd) {
        this.global.showToast('Please enter a minimum amount of ₹10').then();
      } else if ((window as any)?.isNativeApp) {
        (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({
          action: 'addMoney',
          amount: this.amountToAdd
        }));
      } else {
        this.initiateTxn(this.amountToAdd);
      }
    } else {
      this.global.showToast('Please enter a valid amount').then();
    }
  };

  addAmount = (amount) => {
    this.amountToAdd = +this.amountToAdd + +amount;
  };

  initiateTxn = (amount) => {
    this.global.showLoader = true;
    this.api.initiateTxn({
      amount: +amount,
      callbackUrl: this.callbackUrl
    }).subscribe(async res => {
      this.global.showLoader = false;

      if (res?.result?.status === 'failed') {
        this.global.showToast(res?.result?.statusData?.message || 'Something went wrong!').then();
      } else if (res?.result?.mode === 'phonepe') {
        const phonePePGUrl = res?.result?.gatewayClientInfo?.redirection?.redirectInfo?.url;
        if (phonePePGUrl) {
          window.open(phonePePGUrl, '_self');
        } else {
          this.global.showToast('Something went wrong!').then();
        }
      } else if (res?.result?.mode === 'paytm') {
        const config = {
          root: '',
          flow: 'DEFAULT',
          data: {
            orderId: res?.result?._id, /* update order id */
            token: res?.result?.gatewayClientInfo?.txnToken || res?.result?.txnToken, /* update token value */
            tokenType: 'TXN_TOKEN',
            amount: res?.result?.amount /* update amount */
          },
          handler: {
            notifyMerchant: (eventName, data) => {
              console.log('notifyMerchant handler function called');
              console.log('eventName => ', eventName);
              console.log('data => ', data);
            }
          }
        };

        if ((window as any)?.Paytm && (window as any)?.Paytm?.CheckoutJS) {
          console.log('inside paytm');
          // Initialize configuration using init method
          (window as any).Paytm.CheckoutJS.init(config).then(() => {
            // After successfully updating configuration, invoke JS Checkout
            (window as any).Paytm.CheckoutJS.invoke();
          }).catch((error) => {
            console.log('error => ', error);
          });
        }
      }
    }, (err: HttpErrorResponse) => {
      this.global.showLoader = false;
      // this.global.showToast(err?.error?.message || 'Please check your network connection!').then();
    });
  };

  @HostListener('window:receivedLocationInfo', ['$event'])
  onGetLocation(): void {
    console.log('receivedLocationInfo');
    this.addMoney();
  }
}
