import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {StorageService} from './storage.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  accessTokenSavedAt: any = null;
  accessToken: any = '';
  refreshToken: any = '';

  fcmToken: any = '';

  loginStatus = false;
  loginObserver: Subject<boolean> = new Subject<boolean>();

  userObj: any = {};
  profileObserver: Subject<any> = new Subject<any>();

  walletBalance = 0;
  withdrawBalance = 0;

  userGeoData: any = {};

  constructor(
    private storage: StorageService
  ) {
    this.init().then();
  }

  init = async () => {
    this.accessTokenSavedAt = await this.storage.getItem('accessTokenSavedAt');
    this.accessToken = await this.storage.getItem('access_token');
    this.refreshToken = await this.storage.getItem('refresh_token');
    this.userObj = await this.storage.getItem('userObj');
    this.userGeoData = await this.storage.getItem('userGeoData');
    this.fcmToken = await this.storage.getItem('fcmToken');

    this.loginStatus = this.isLoggedIn();
    this.loginObserver.next(this.isLoggedIn());
    this.loginObserver.subscribe(async value => {
      if (!value) {
        await this.storage.removeItem('userObj');
        await this.storage.removeItem('access_token');
        await this.storage.removeItem('refresh_token');
        this.userObj = {};
        this.accessToken = '';
        this.refreshToken = '';
      }
      this.loginStatus = this.isLoggedIn();
    });

    this.profileObserver.subscribe(async value => {
      this.userObj = value;
      await this.storage.setItem('userObj', JSON.stringify(value || {}));
      this.loginStatus = this.isLoggedIn();
    });

    this.loginStatus = this.isLoggedIn();
  };

  isLoggedIn = () => !!(this.accessToken && this.accessToken !== 'null' && this.userObj?._id);

  isProfileComplete = () => this.isLoggedIn() && this.userObj?.profile?.displayName;

  isTokenExpired = async () => {
    this.accessTokenSavedAt = await this.storage.getItem('accessTokenSavedAt');
    this.accessToken = await this.storage.getItem('access_token');

    return this.accessToken && (!this.accessTokenSavedAt || moment().diff(moment(this.accessTokenSavedAt), 'minutes') > 50);
  };

  isSuperCreator = () => this.userObj?.privileges?.includes('super_creator');
}
