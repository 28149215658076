import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  utmParams: any = {};
  sharedByUserId = '';

  constructor(
    private user: UserService
  ) {
  }

  isInsideWebview = () => this.isRNApp() || (window as any)?.navigator?.userAgent === 'Android App' || this.user.fcmToken;

  isRNApp = () => (window as any)?.isNativeApp;

  trackEvent = (eventName, properties = {}) => {
    const allProperties: any = {
      ...properties,
      userId: this.user.userObj?._id,
      userDisplayName: this.user.userObj?.profile?.displayName,
      phoneNumber: this.user.userObj?.profile?.phone?.number,
      fcmToken: this.user.fcmToken,
      isApp: this.isInsideWebview(),
      isRNApp: this.isRNApp(),
      appVersion: (window as any)?.appVersion || '',
      platform: this.isInsideWebview() ? 'android-app' : 'browser',
      ...this.utmParams
    };
    if (!allProperties?.sharedByUserId && this.sharedByUserId) {
      allProperties.sharedByUserId = this.sharedByUserId;
    }

    gtag('event', eventName, allProperties);

    mixpanel?.track(eventName, allProperties);

    // console.log('Event name: ' + eventName, allProperties);
  };

  setUserPropertiesForAnalytics = () => {
    if (this.user.userObj?._id) {
      // Set this to a unique identifier for the user performing the event.
      // eg: their ID in your database or their email address.
      mixpanel.identify(this.user.userObj?._id);
      const userProperties: any = {};
      if (this.user.userObj?.profile?.displayName) {
        userProperties.name = this.user.userObj?.profile?.displayName;
      }
      if (this.user.userObj?.profile?.phone?.number) {
        userProperties.phoneNumber = this.user.userObj?.profile?.phone?.number;
      }
      if (this.user.fcmToken) {
        userProperties.fcmToken = this.user.fcmToken;
      }
      if (this.user?.userObj?.createdAt) {
        userProperties.createdAt = this.user.userObj?.createdAt;
      }
      mixpanel.people.set({...userProperties});

      // console.log(userProperties, userProperties);
    }
  };
}
