import {Inject, Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {AnalyticsService} from './analytics.service';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {GlobalService} from './global.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private toastController: ToastController,
    private analytics: AnalyticsService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private global: GlobalService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  updateMetaTags = (data) => {
    if (!data?.title) {
      data.title = this.global.metaTitle;
    }
    if (!data?.description) {
      data.description = this.global.metaDesc;
    }
    if (!data?.route) {
      data.route = '/';
    }
    data.url = `https://playlo.in${data?.route}`;

    if (!data?.image) {
      data.image = this.global.metaImg;
    }

    if (window.location.href?.indexOf('home.playlo.in') !== -1 || window.location.href?.indexOf('/landing') !== -1) {
      // const pageTitle = 'The Ultimate Cashback Collection - Explore 100+ Top Brands | Highfox.Shop';
      // const pageDesc = 'Discover the ultimate cashback solution at highfox.shop, India\'s leading cashback cards superstore.';
      // data.title = pageTitle;
      // data.description = pageDesc;
      data.url = 'https://home.playlo.in/landing';
    } else if (window.location.href?.indexOf('creator.playlo.in') !== -1 || window.location.href?.indexOf('/creator') !== -1) {
      const pageTitle = 'Become a Creator at PlayLo | Creator.PlayLo.in';
      // const pageDesc = 'Discover the ultimate cashback solution at highfox.shop, India\'s leading cashback cards superstore.';
      data.title = pageTitle;
      // data.description = pageDesc;
      data.url = 'https://creator.playlo.in/creator';
    } else if (window.location.href?.indexOf('highfox') !== -1 || window.location.href?.indexOf('/gift-cards') !== -1) {
      const pageTitle = 'The Ultimate Cashback Collection - Explore 100+ Top Brands | Highfox.Shop';
      const pageDesc = 'Discover the ultimate cashback solution at highfox.shop, India\'s leading cashback cards superstore.';
      data.title = pageTitle;
      data.description = pageDesc;
      data.url = 'https://highfox.shop/gift-cards';
      data.image = 'https://highfox.shop/assets/gift-cards/highfox-logo.png';
    }

    this.titleService.setTitle(data?.title);
    this.meta.updateTag({property: 'og:title', content: data?.title});
    this.meta.updateTag({name: 'twitter:title', content: data?.title});

    this.meta.updateTag({name: 'description', content: data?.description});
    this.meta.updateTag({property: 'og:description', content: data?.description});
    this.meta.updateTag({name: 'twitter:description', content: data?.description});

    this.meta.updateTag({property: 'og:url', content: data?.url});
    this.meta.updateTag({name: 'twitter:url', content: data?.url});
    this.updateCanonicalLink(data?.url);

    this.meta.updateTag({property: 'og:image', content: data?.image});
    this.meta.updateTag({name: 'twitter:image', content: data?.image});
  };

  updateCanonicalLink = (url) => {
    const canonical = this.document.querySelector('link[rel="canonical"]');
    if (canonical) {

    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', url);
    }
  };
}
