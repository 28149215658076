import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-how-to-play',
  templateUrl: './how-to-play.component.html',
  styleUrls: ['./how-to-play.component.scss'],
})
export class HowToPlayComponent implements OnInit {
  tabs = [
    {label: 'T20', value: 't20'},
    {label: 'ODI', value: 'odi'},
    {label: 'TEST', value: 'test'}
  ];
  selectedTab = this.tabs?.[0]?.value;

  pointsSystem = {
    t20: [
      {
        name: 'Batting Points',
        items: [
          {label: 'Runs', points: 0.5},
          {label: '4s', points: 0.5},
          {label: '6s', points: 1},
          {label: 'Half-Century Bonus', points: 4},
          {label: 'Century Bonus', points: 8},
          {label: 'Dismissal for Duck', points: -2}
        ]
      },
      {
        name: 'Bowling Points',
        items: [
          {label: 'Wicket', points: 10},
          {label: '4 Wicket Bonus', points: 4},
          {label: '5 Wicket Bonus', points: 8},
          {label: 'Maiden Over', points: 4}
        ]
      },
      {
        name: 'Fielding Points',
        items: [
          {label: 'Catch', points: 4},
          {label: 'Stumping', points: 6},
          {label: 'Runout', points: 4}
        ]
      },
      {
        name: 'Economy Rate (min 2 overs)',
        items: [
          {label: 'Below 4 runs', points: 3},
          {label: 'Between 4 to 5 runs', points: 2},
          {label: 'Between 5 to 6 runs', points: 1},
          {label: 'Between 9 to 10 runs', points: -1},
          {label: 'Between 10 to 11 runs', points: -2},
          {label: 'Above 11 runs', points: -3}
        ]
      },
      {
        name: 'Strike Rate (min 10 balls)',
        items: [
          {label: 'Between 60 to 70', points: -1},
          {label: 'Between 50 to 60', points: -2},
          {label: 'Below 50', points: -3}
        ]
      },
      {
        name: 'C & VC',
        items: [
          {label: 'Captain', points: '2X'},
          {label: 'Vice-Captain', points: '1.5X'}
        ]
      }
    ],
    odi: [
      {
        name: 'Batting Points',
        items: [
          {label: 'Runs', points: 0.5},
          {label: '4s', points: 0.5},
          {label: '6s', points: 1},
          {label: 'Half-Century Bonus', points: 2},
          {label: 'Century Bonus', points: 4},
          {label: 'Dismissal for Duck', points: -3}
        ]
      },
      {
        name: 'Bowling Points',
        items: [
          {label: 'Wicket', points: 12},
          {label: '4 Wicket Bonus', points: 2},
          {label: '5 Wicket Bonus', points: 4},
          {label: 'Maiden Over', points: 2}
        ]
      },
      {
        name: 'Fielding Points',
        items: [
          {label: 'Catch', points: 4},
          {label: 'Stumping', points: 6},
          {label: 'Runout', points: 4}
        ]
      },
      {
        name: 'Economy Rate (min 5 overs)',
        items: [
          {label: 'Below 2.5 runs', points: 3},
          {label: 'Between 2.5 to 3.5 runs', points: 2},
          {label: 'Between 3.5 to 4.5 runs', points: 1},
          {label: 'Between 7 to 8 runs', points: -1},
          {label: 'Between 8 to 9 runs', points: -2},
          {label: 'Above 9 runs', points: -3}
        ]
      },
      {
        name: 'Strike Rate (min 20 balls)',
        items: [
          {label: 'Between 50 to 60', points: -1},
          {label: 'Between 40 to 50', points: -2},
          {label: 'Below 40', points: -3}
        ]
      },
      {
        name: 'C & VC',
        items: [
          {label: 'Captain', points: '2X'},
          {label: 'Vice-Captain', points: '1.5X'}
        ]
      }
    ],
    test: [
      {
        name: 'Batting Points',
        items: [
          {label: 'Runs', points: 0.5},
          {label: '4s', points: 0.5},
          {label: '6s', points: 1},
          {label: 'Half-Century Bonus', points: 2},
          {label: 'Century Bonus', points: 4},
          {label: 'Dismissal for Duck', points: -4}
        ]
      },
      {
        name: 'Bowling Points',
        items: [
          {label: 'Wicket', points: 8},
          {label: '4 Wicket Bonus', points: 2},
          {label: '5 Wicket Bonus', points: 4}
        ]
      },
      {
        name: 'Fielding Points',
        items: [
          {label: 'Catch', points: 4},
          {label: 'Stumping', points: 6},
          {label: 'Runout', points: 4}
        ]
      },
      {
        name: 'C & VC',
        items: [
          {label: 'Captain', points: '2X'},
          {label: 'Vice-Captain', points: '1.5X'}
        ]
      }
    ]
  };

  constructor() {
  }

  ngOnInit() {
  }

  changeSelectedTab = (tab) => {
    this.selectedTab = tab;
  };
}
