import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Output() closeBS = new EventEmitter<boolean>();
  @Input() hideCross = false;
  initiated = false;

  constructor() {
    setTimeout(() => {
      this.initiated = true;
    }, 100);
  }

  ngOnInit() {
  }

  onBSWrapperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  closeModal = () => {
    if (this.closeBS) {
      this.closeBS.emit(true);
    }
  };
}
