import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonContent, ModalController} from '@ionic/angular';
import {ApiService} from '../services/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {GlobalService} from '../services/global.service';
import {UserService} from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsService} from '../services/analytics.service';
import {LoginModalComponent} from '../components/login-modal/login-modal.component';
import * as moment from 'moment';
import {MetaService} from '../services/meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  selectedTab = 'contests';
  matches: any = this.global.matches?.list?.filter(match => moment(match?.estimated_end_date).valueOf() > moment().valueOf()) || [];
  activityId = this.global.selectedMatchId;
  selectedMatchId = this.global.selectedMatchId;
  appliedFilters: any = {
    entryFee: [],
    prizePool: []
  };
  areFiltersOpened = false;
  justEntered = true;
  matchDetailsIntervalId;
  contestsFetchedAt: any = null;
  showMatchScorecard = false;
  fantasyScores: any = {};
  costFilters: any = [
    {label: 'All', min: 0, max: 100000, isSelected: true},
    {label: 'Under ₹100', min: 0, max: 99},
    {label: '₹100 & above', min: 100, max: 100000}
  ];

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService,
    public global: GlobalService,
    public user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public analytics: AnalyticsService,
    private metaService: MetaService
  ) {
    this.metaService.updateMetaTags({
      route: '/contests'
    });
  }

  ngOnInit() {
    this.user.loginObserver.subscribe(value => {
      if (this.global.matches?.length > 0) {
        this.getMatchesData(true);
      }
    });

    this.route.queryParams.subscribe(res => {
      if (res.matchId) {
        this.selectedMatchId = res.matchId;
        this.activityId = res.matchId;
        this.global.selectedMatchId = res.matchId;
        this.fetchContests(this.activityId);
        this.getVerifiedCreators();
      }
    });
  }

  ionViewWillEnter() {
    if (this.router.url?.indexOf('matchId') === -1 && this.global.selectedMatchId) {
      this.selectedMatchId = this.global.selectedMatchId;
      this.activityId = this.global.selectedMatchId;
      const selectedMatchIndex = this.matches.findIndex(match => match?._id === this.selectedMatchId);
      this.global.scrollActiveMatchHorizontally('homeMatchWidgetsContainerWrapper', selectedMatchIndex);
    }
    this.reloadPage().then();

    if (this.matchDetailsIntervalId) {
      clearInterval(this.matchDetailsIntervalId);
    }
    this.matchDetailsIntervalId = setInterval(() => {
      if (this.selectedMatchId && this.global.isMatchStarted(this.getSelectedMatch())) {
        const teamIds = [];
        this.global.joinedContests?.[this.selectedMatchId]?.list?.map(contest => {
          contest?.operateOptions?.targets[0]?.joinParams?.choice?.map(teamObj => {
            if (!teamIds?.includes(teamObj?.id)) {
              teamIds.push(teamObj?.id);
            }
          });
        });

        this.getLiveMatchDetails(teamIds);
      }
    }, 20000); // refresh in every 20s
  }

  ngOnDestroy() {
    if (this.matchDetailsIntervalId) {
      clearInterval(this.matchDetailsIntervalId);
    }
  }

  ionViewWillLeave() {
    if (this.matchDetailsIntervalId) {
      clearInterval(this.matchDetailsIntervalId);
    }
  }

  reloadPage = async (event = null, forceReload = false) => {
    if (!this.global.matches?.updatedAt || moment().diff(moment(this.global.matches?.updatedAt), 'minutes') > 30) {
      this.getMatchesData(forceReload);
    } else {
      // if (forceReload || !this.contestsFetchedAt || moment().diff(moment(this.contestsFetchedAt), 'minutes') > 15) {
      if (!this.selectedMatchId && this.matches?.length > 0) {
        this.selectedMatchId = this.matches?.[0]?._id;
        this.activityId = this.matches?.[0]?._id;
        this.global.selectedMatchId = this.selectedMatchId;
      }

      this.fetchContests(this.activityId, forceReload);
      // }
    }

    if (event) {
      setTimeout(() => {
        if (event?.target) {
          event.target.complete();
        }
      }, 1000);
    }
  };

  getSelectedMatch = () => this.matches?.find((match: any) => (match?._id === this.selectedMatchId)) || {};

  openFiltersBS = async () => {
    this.areFiltersOpened = true;
  };

  onApplyingFilters = (event) => {
    this.appliedFilters = event;
    this.areFiltersOpened = false;
  };

  onBSWrapperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onMatchSelect = (matchId, index) => {
    this.analytics.trackEvent('home_matchWidget_click', {matchId});

    this.selectedMatchId = matchId;
    this.activityId = matchId;
    this.global.selectedMatchId = matchId;
    this.global.scrollActiveMatchHorizontally('homeMatchWidgetsContainerWrapper', index);

    if (this.global.joinedContests?.[this.selectedMatchId]?.length > 1) {
      this.global.collapseJoinedContests = true;
    }

    this.router.navigate(['/contests/'], {queryParams: {matchId}}).then();
  };

  getMatchesData = (forceReload = false) => {
    this.global.showLoader = true;
    this.api.getMatchesData().subscribe((res: any) => {
      this.justEntered = false;
      this.matches = res?.result || [];
      this.global.matches.list = this.matches;
      this.global.matches.updatedAt = moment().valueOf();

      this.matches?.filter(match => match?._id)?.map(match => {
        this.global.allMatches[match?._id] = {...match};
      });

      if (res?.result?.[0]?._id && (!this.selectedMatchId || !this.matches?.find(match => match?._id === this.selectedMatchId))) {
        this.selectedMatchId = res?.result?.[0]?._id;
        this.global.selectedMatchId = this.selectedMatchId;
        this.activityId = res?.result?.[0]?._id;
        this.getVerifiedCreators();
      }
      if (this.activityId) {
        this.fetchContests(this.activityId, forceReload);
      } else {
        this.global.showLoader = false;
      }

      setTimeout(() => {
        const selectedMatchIndex = this.matches.findIndex(match => match?._id === this.selectedMatchId);
        this.global.scrollActiveMatchHorizontally('homeMatchWidgetsContainerWrapper', selectedMatchIndex);
      }, 200);
    }, (err: HttpErrorResponse) => {
      this.global.showLoader = false;
      // this.global.showToast(err?.error?.message || 'Please check your network connection!').then();
    });
  };

  fetchContests = (activityId, forceReload = false) => {
    if (!activityId) {
      return;
    }

    if (this.user.accessToken) {
      this.getJoinedContests(activityId, false, forceReload);
    } else {
      this.getAllContests(activityId, false, forceReload);
    }

    this.getPlayersInfo(Object.keys(this.getSelectedMatch()?.players || {}));
  };

  getJoinedContests = (activityId = this.activityId, loadMore = false, forceReload = false) => {
    if (loadMore || forceReload || !this.global.joinedContests?.[this.selectedMatchId]) {
      this.global.showLoader = true;
    }

    this.api.getJoinedContests({
      activityId,
      offset: loadMore ? this.global.joinedContests[this.selectedMatchId]?.offset : null,
      force: forceReload
    }).subscribe((res: any) => {
      this.justEntered = false;

      const fetchedContests = res?.result?.list?.filter(item => item?.operateOptions?.linkedTo?.item === this.selectedMatchId) || [];

      if (!this.global.joinedContests?.[this.selectedMatchId] && fetchedContests?.length > 1) {
        this.global.collapseJoinedContests = true;
      }

      this.global.joinedContests[this.selectedMatchId] = {
        ...res?.result,
        list: (loadMore ? (this.global.joinedContests[this.selectedMatchId]?.list || []) : [])?.concat(fetchedContests || [])
      };

      this.getAllContests(activityId, false, forceReload);
    }, (err: HttpErrorResponse) => {
      this.global.showLoader = false;
      this.global.joinedContests[this.selectedMatchId] = {
        ...err,
        list: this.global.joinedContests[this.selectedMatchId]?.list || []
      };
      this.getAllContests(activityId, false, forceReload);
    });
  };

  getAllContests = (activityId = this.activityId, loadMore = false, forceReload = false) => {
    this.global.showLoader = false;
    this.justEntered = false;
    this.global.upcomingContests[this.selectedMatchId] = {
      list: []
    };

    // if (loadMore || forceReload || !this.global.upcomingContests[this.selectedMatchId]) {
    //   this.global.showLoader = true;
    // }
    //
    // const selectedCostFilter = this.costFilters.find(filter => filter?.isSelected) || this.costFilters[0];
    // this.api.getContests({
    //   activityId,
    //   offset: loadMore ? this.global.upcomingContests[this.selectedMatchId]?.offset : [],
    //   force: forceReload,
    //   filters: {
    //     cost: {min: selectedCostFilter?.min, max: selectedCostFilter?.max},
    //     target: '',
    //     creator: this.global.selectedCreators?.[this.selectedMatchId]?.length ? this.global.selectedCreators?.[this.selectedMatchId] : null
    //   }
    // }).subscribe(async (res: any) => {
    //   this.global.showLoader = false;
    //   this.contestsFetchedAt = moment().valueOf();
    //   this.justEntered = false;
    //
    //   this.global.upcomingContests[this.selectedMatchId] = {
    //     ...res?.result,
    //     list: (loadMore ? (this.global.upcomingContests[this.selectedMatchId]?.list || []) : [])?.concat(res?.result?.list || [])
    //   };
    //
    //   const teamIds = [];
    //   (this.global.joinedContests[this.selectedMatchId]?.list || [])?.concat(this.global.upcomingContests[this.selectedMatchId]?.list || [])?.map(contest => {
    //     contest?.operateOptions?.targets[0]?.joinParams?.choice?.map(teamObj => {
    //       if (!teamIds?.includes(teamObj?.id)) {
    //         teamIds.push(teamObj?.id);
    //       }
    //     });
    //
    //     this.global.contests[contest?._id] = {...contest};
    //   });
    //   this.getLiveMatchDetails(teamIds);
    // }, (err: HttpErrorResponse) => {
    //   this.global.showLoader = false;
    //   this.global.upcomingContests[this.selectedMatchId] = {
    //     ...err,
    //     list: this.global.upcomingContests[this.selectedMatchId]?.list || []
    //   };
    //   this.global.showToast(err?.error?.message || err?.message || 'Please check your network connection!').then();
    // });
  };

  getTeams = (teamIds, invalidate = false) => {
    this.api.getTeams({teamIds, invalidate});
  };

  getPlayersInfo = (playersIds) => {
    this.api.getPlayersInfo({playersIds});
  };

  onCreateContestClick = () => {
    this.analytics.trackEvent('home_createNew_click', {matchId: this.selectedMatchId});

    if (this.user.isProfileComplete()) {
      if (this.global.isMatchStarted(this.getSelectedMatch())) {
        this.router.navigate(['/created-contests']).then();
      } else {
        this.router.navigate(['/create-contest'], {queryParams: {matchId: this.selectedMatchId}}).then();
      }
    } else {
      this.openLoginModal().then();
    }
  };

  openLoginModal = async () => {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      cssClass: 'loginModal'
    });
    modal.present().then();

    const {data, role} = await modal.onWillDismiss();
    if (role === 'success') {
      if (this.global.isMatchStarted(this.getSelectedMatch())) {
        this.router.navigate(['/created-contests']).then();
      } else {
        this.router.navigate(['/create-contest'], {queryParams: {matchId: this.selectedMatchId}}).then();
      }
    }
  };

  getMatchDetails = () => {
    this.api.getMatchDetails({matchId: this.selectedMatchId}).subscribe(res => {
      this.global.showLoader = false;
      if (res?.result?._id) {
        this.global.allMatches[res?.result?._id] = {...res?.result};

        const matchIndex = this.global.matches?.list?.findIndex(match => match?._id === this.selectedMatchId);
        if (matchIndex >= 0) {
          this.global.matches.list[matchIndex] = res?.result;
        }

        if (this.global.isMatchCompleted(res?.result)) {
          this.getMatchesData();
        }
      }
    }, (err: HttpErrorResponse) => {
    });
  };

  getLiveMatchDetails = (teamIds = []) => {
    this.api.getLiveMatchDetails({
      matchId: this.selectedMatchId,
      teamIds
    }).subscribe(res => {
      if (res?.result) {
        this.fantasyScores = {};
        res?.result?.fantasy?.points?.forEach(item => {
          this.fantasyScores[item?.player_key] = {...item};
        });

        const match: any = {...this.getSelectedMatch()};
        if (res?.result?.match?.play) {
          match.play = res?.result?.match?.play;
        }
        if (res?.result?.match?.players) {
          match.players = res?.result?.match?.players;
        }
        if (res?.result?.match?.play_status) {
          match.play_status = res?.result?.match?.play_status;
        }
        if (res?.result?.match?.status) {
          match.status = res?.result?.match?.status;
        }
        if (res?.result?.match?.squad) {
          match.squad = res?.result?.match?.squad;
        }

        const matchIndex = this.global.matches?.list?.findIndex(match => match?._id === this.selectedMatchId);
        if (matchIndex >= 0) {
          this.global.matches.list[matchIndex] = match;
        }

        this.global.allMatches[match?._id] = {...match};

        res?.result?.teams?.map(team => {
          if (team?._id) {
            this.global.teams[team?._id] = team;
          }
        });

        if (this.global.isMatchCompleted(match)) {
          this.getMatchesData();
        }
      }
    }, (err: HttpErrorResponse) => {
    });
  };

  onScrollToTop = () => {
    this.content.scrollToTop().then();
  };

  onDownloadAppClick = () => {
    this.analytics.trackEvent('appDownloadHomeBanner_download_clicked');
    location.href = this.global.getAppDownloadLink();
  };

  getTimeLeft = (contest) => moment(contest?.operateOptions?.startTime).fromNow();

  selectCostFilter = (selectedIndex) => {
    this.costFilters?.map((filter, index) => {
      if (index !== selectedIndex) {
        filter.isSelected = false;
      } else {
        filter.isSelected = true;
        this.getAllContests(this.activityId);
      }
    });
  };

  onCreatorToggle = (creatorObj) => {
    const index = this.global.selectedCreators?.[this.selectedMatchId]?.findIndex(creatorId => creatorId === creatorObj?._id);
    if (this.global.selectedCreators?.[this.selectedMatchId]?.length && index !== -1) {
      this.global.selectedCreators?.[this.selectedMatchId].splice(index, 1);
    } else {
      if (!this.global.selectedCreators?.[this.selectedMatchId]?.length) {
        this.global.selectedCreators[this.selectedMatchId] = [];
      }
      this.global.selectedCreators?.[this.selectedMatchId].push(creatorObj?._id);
    }

    this.global.showLoader = true;
    this.getAllContests(this.activityId);
  };

  onClearCreatorFilters = () => {
    this.global.selectedCreators[this.selectedMatchId] = [];
    this.global.showLoader = true;
    this.getAllContests(this.activityId);
  };

  getVerifiedCreators = () => {
    this.api.getVerifiedCreators({
      matchIds: this.selectedMatchId ? [this.selectedMatchId] : []
    }).subscribe((res: any) => {
      this.global.verifiedCreators[this.selectedMatchId] = res?.result?.list || [];
    }, (err: HttpErrorResponse) => {
    });
  };

  getUpcomingContestsCount = () => this.global.upcomingContests[this.selectedMatchId]?.total || this.global.upcomingContests[this.selectedMatchId]?.list?.length;
}
