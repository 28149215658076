import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {environment} from '../../environments/environment';

// Web app’s Firebase configuration
const firebaseConfig = {
  apiKey: environment.firebaseConfig?.apiKey,
  authDomain: environment.firebaseConfig?.authDomain,
  projectId: environment.firebaseConfig?.projectId,
  storageBucket: environment.firebaseConfig?.storageBucket,
  messagingSenderId: environment.firebaseConfig?.messagingSenderId,
  appId: environment.firebaseConfig?.appId,
  measurementId: environment.firebaseConfig?.measurementId
};


//If a firebase app hasn't already been created
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
