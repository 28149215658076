import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-match-stats',
  templateUrl: './match-stats.component.html',
  styleUrls: ['./match-stats.component.scss'],
})
export class MatchStatsComponent implements OnInit {
  @Input() match;
  @Input() contests: any = [];
  @Input() fantasyScores: any = {};
  @Output() scorecardClickEvent = new EventEmitter<any>();

  constructor(
    public global: GlobalService
  ) {
  }

  ngOnInit() {
  }

  onScorecardClick = () => {
    this.scorecardClickEvent.emit(true);
  };

  isMatchStarted = () => moment(this.match?.start_at).valueOf() < moment().valueOf();

  isMatchEnded = () => this.match?.status === this.global.matchStates.completed;

  getTimeLeft = () => moment(this.match?.start_at).fromNow();

  getTeamScore = (teamIndex) => {
    const inningCode = this.match?.play?.innings_order?.[teamIndex];
    return this.match?.play?.innings?.[inningCode]?.score?.runs || 0;
  };

  getTeamWickets = (teamIndex) => {
    const inningCode = this.match?.play?.innings_order?.[teamIndex];
    return this.match?.play?.innings?.[inningCode]?.wickets || 0;
  };

  getTeamOvers = (teamIndex) => {
    const inningCode = this.match?.play?.innings_order?.[teamIndex];
    return (this.match?.play?.innings?.[inningCode]?.overs?.[0] || 0) + '.' + (this.match?.play?.innings?.[inningCode]?.overs?.[1] || 0);
  };

  getTeamBalls = (teamIndex) => {
    const inningCode = this.match?.play?.innings_order?.[teamIndex];
    return ((this.match?.play?.innings?.[inningCode]?.overs?.[0] || 0) * 6) + (this.match?.play?.innings?.[inningCode]?.overs?.[1] || 0);
  };

  isWinning = () => this.contests?.find(contest => this.global.isWinning(contest));

  getMyWinnings = () => this.global.formatNumber(this.contests.reduce((acc, contest) => (acc + +this.global.getMyWinnings(contest)), 0), 0);

  getWinningContestsCount = () => this.contests?.filter(contest => this.global.isWinning(contest))?.length || 0;
}
